import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { useAuth } from 'react-oidc-context';

const Sidebar = () => {
  const { dispatch } = useContext(AppContext);
  const auth = useAuth();

  const setActiveNav = (event: any) => {
    //remove all active navs
    let navLinks = document.getElementsByClassName('nav-link');
    for (let i = 0; i < navLinks.length; i++) {
      navLinks[i].classList.remove('active');
    }
    //set active for the current one
    event.target.classList.add('active');

    //scroll to top
    let scrollHere: any = document.getElementById('scroll-here');
    scrollHere.scrollIntoView();

    //close side menu if open
    let sideBar: any = document.getElementById('sidebarMenu');
    sideBar.classList['remove']('show');
  };

  const logOut = (event: any) => {
    dispatch({
      type: 'LOG_OUT',
    });
    auth
      .signoutRedirect()
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });

    //window.location.href = "/";

    // dispatch({
    //   type: "LOG_OUT",
    // });
  };

  return (
    <nav id='sidebarMenu' className='col-md-3 col-lg-2 d-md-block sidebar collapse'>
      <div className='sidebar-sticky pt-3'>
        <ul className='nav flex-column mt-5'>
          <li className='nav-item'>
            <Link to='/fulfilments' className='nav-link nav-link-custom' onClick={setActiveNav}>
              <span className='bi bi-prescription mr-2 text-light'></span> Fulfilments
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/utilization' className='nav-link nav-link-custom' onClick={setActiveNav}>
              <span className='bi bi-bookmark-star mr-2 text-light'></span>Claims Utilization
              <span className='bi bi-stars fs-12 text-danger ml-2 new-feature-indicator'></span>
            </Link>
          </li>
          {/* <li className='nav-item'>
            <Link to='/claims' className='nav-link nav-link-custom' onClick={setActiveNav}>
              <span className='bi bi-capsule-pill mr-1 text-light'></span> Claims
            </Link>
          </li> */}
          <li className='nav-item'>
            <Link to='/refills' className='nav-link nav-link-custom' onClick={setActiveNav}>
              <span className='bi bi-arrow-repeat mr-2 text-light'></span>Refills
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/settings' className='nav-link nav-link-custom' onClick={setActiveNav}>
              <span className='bi bi-gear mr-2 text-light'></span>Settings
            </Link>
          </li>
          <hr />
          <li className='nav-item'>
            <Link to='/logout' className='nav-link nav-link-custom' onClick={logOut}>
              <span className='bi bi-box-arrow-left mr-2 text-light'></span>Log out
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
