import { useState, useEffect } from 'react';
import { IApiCredentialSettings } from '../../interfaces/SettingsInterface';
import { addToast } from '../../utils/toastNotifications';
import LoaderWithText from '../common/LoaderWithText';
import ConfirmationModal from '../common/ConfirmationModal';
import { SettingsTabs } from '../../utils/mappings';
import { endpoints } from '../../utils/URLs';
import axios from 'axios';

const ApiCredentials = ({ tabKey }: { tabKey: string }) => {
  const [apiSettings, setApiSettings] = useState<IApiCredentialSettings | undefined>({
    clientId: '',
    clientSecret: '',
    webhookUrl: '',
  });

  const [showModal, setShowModal] = useState(false);
  const [isKeyGenerated, setKeyGenerated] = useState(false);

  const generateNewkeys = async () => {
    const url = `${endpoints.profile.credentials}/generate`;

    try {
      const response = await axios.get(url);
      const data = response.data;
      setApiSettings(data);
      addToast('New Keys Generated', 'success');
      setKeyGenerated(true);
      setShowModal(false);
    } catch (error) {
      addToast('An error occured', 'error');
    }
  };

  const copyKeys = (inputName: string) => {
    let message = 'Loading';
    if (inputName === 'ClientId') {
      message = apiSettings?.clientId as string;
    } else {
      message = apiSettings?.clientSecret as string;
    }

    navigator.clipboard
      .writeText(message)
      .then(() => {
        addToast('Copied to clipboard');
      })
      .catch((err) => {
        console.error('Async: Could not copy text: ', err);
      });
  };

  useEffect(() => {
    if (tabKey !== SettingsTabs.ApiCredentials) {
      return;
    }
    //setKeyGenerated(false);
  }, [tabKey]);

  let confirmModal = (
    <ConfirmationModal
      show={showModal}
      hide={() => setShowModal(false)}
      title='Generate new keys'
      okText='Generate'
      okCallback={generateNewkeys}
      cancelCallback={() => setShowModal(false)}
      content='This action will invalidate your previous keys. You will need to update your applications with the new keys'
    />
  );

  return (
    <div>
      <h5 className='mt-5 text-center'>API Credentials</h5>

      {apiSettings ? (
        <div className='mt-5'>
          {isKeyGenerated ? (
            <div>
              <div className='form-group row mt-4'>
                <label className='col-sm-3 col-form-label'>Client Id</label>
                <div className='col-sm-7 col-9'>
                  <input
                    type='text'
                    className='form-control'
                    value={apiSettings.clientId}
                    readOnly={true}
                  />
                </div>
                <div className='col-sm-1 col-1'>
                  <span
                    className='btn btn-block bi bi-clipboard'
                    title='Copy'
                    onClick={() => copyKeys('ClientId')}
                  ></span>
                </div>
              </div>
              <div className='form-group row mt-4'>
                <label className='col-sm-3 col-form-label'>Client Secret</label>
                <div className='col-sm-7 col-9'>
                  <input
                    type='text'
                    className='form-control'
                    readOnly={true}
                    value={apiSettings.clientSecret}
                  />
                </div>
                <div className='col-sm-1 col-1'>
                  <span
                    className='btn btn-block bi bi-clipboard'
                    title='Copy'
                    onClick={() => copyKeys('ClientSecret')}
                  ></span>
                </div>
              </div>
              <div className='text-info form-group row mt-4 justify-content-center'>
                <div className='col-sm-6'>
                  Please copy your credentials to a safe location after generating it. The keys
                  cannot be viewd after navigating from this screen.
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          <div className='form-group row justify-content-center mt-4'>
            <div className='col-sm-6'>
              <button className='btn btn-success btn-block' onClick={() => setShowModal(true)}>
                Generate new keys
              </button>
            </div>
          </div>
          {/* Do not delete. Will be enabled when feature is ready */}
          {/* <form id="webhook-form">
            <div className="form-group row mt-5">
              <label className="col-sm-3 col-form-label">Webhook URL</label>
              <div className="col-sm-7">
                <input
                  type="url"
                  className="form-control"
                  placeholder="https://mywebhookurl.com"
                  value={apiSettings.webhookUrl}
                  required
                />
              </div>
            </div>

            <div className="form-group row justify-content-center mt-4">
              <div className="col-sm-6">
                <button
                  type="submit"
                  className="btn btn-success btn-block"
                  onClick={saveWebhookUrl}
                >
                  Save Changes
                </button>
              </div>
            </div>
          </form> */}
        </div>
      ) : (
        <div>
          <LoaderWithText />
        </div>
      )}
      {confirmModal}
    </div>
  );
};

export default ApiCredentials;
