export interface IHmoSummary {
  totalCost: number;
  averageCost: number;
  avgCostPerMonth: number;
  totalEnrollees: number;
  totalFulfilments: number;
  dateFetched: string;
}

export interface IEnrolleeSummary {
  enrolleeName: string;
  enrolleeId: string;
  totalCost: number;
  totalFulfilments: number;
  dateCreated: string;
}

// export interface ISummaryProps {
//   totalSpend: number;
//   avgCostPerMemberPerMonth: number;
//   avgCostPerFulfillment: number;
//   totalMembers: number;
//   totalFulfillments: number;
// }

// export interface IHmoData {
//   date: string;
//   name: string;
//   enrolleeId: string;
//   totalCost: number;
//   encounterCount: number;
//   location: string;
// }

// export interface IEnrolleeData {
//   date: string;
//   totalCost: number;
//   medications: string;
//   location: string;
// }

// export interface IHmoLevelProps {
//   summary: ISummaryProps;
//   data: IHmoData[];
//   onViewClick: (enrolleeId: string) => void;
// }

// export interface IEnrolleeLevelProps {
//   enrollee: IHmoData;
//   data: IEnrolleeData[];
//   onBackClick: () => void;
// }

export interface IDataCardProps {
  title: string;
  value: number;
  iconClass: string;
  iconBgClass: string;
  isCurrency: boolean;
}

/* Initializations */

export const defaultHmoSummary: IHmoSummary = {
  totalCost: 0,
  totalEnrollees: 0,
  totalFulfilments: 0,
  averageCost: 0,
  avgCostPerMonth: 0,
  dateFetched: '',
};
