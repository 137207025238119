import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';

let sentryDsn = process.env.REACT_APP_SENTRY_DSN;

if (sentryDsn) {
  Sentry.init({
    ignoreErrors: [
      'Error: NotSupportedError: The element has no supported sources',
      'The play method is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
      "NotAllowedError: play() failed because the user didn't interact with the document first. https://goo.gl/xX8pDD",
      'The media resource indicated by the src attribute or assigned media provider object was not suitable.',
    ],
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.2,
    maxBreadcrumbs: 10,
  });
}
ReactDOM.render(
  <React.StrictMode>
    <span id='scroll-here'></span>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
