let BASE_URL = process.env.REACT_APP_BASE_URL;

let endpoints = {
  Auth: {
    mainUrl: BASE_URL + '/api/auth/public',
  },
  fulfilments: {
    mainUrl: BASE_URL + '/app/partner/fulfilments',
  },
  fulfilmentPatients: {
    mainUrl: BASE_URL + '/app/partner/fulfilmentpatients',
  },
  claims: {
    mainUrl: BASE_URL + '/app/partner/claims',
  },
  subscriptions: {
    mainUrl: BASE_URL + '/app/partner/subscriptions',
  },
  profile: {
    mainUrl: BASE_URL + '/app/partner/partnerprofile',
    credentials: BASE_URL + '/app/partner/partnerprofile/credentials',
  },
  pricelist: {
    mainUrl: BASE_URL + '/app/partner/pricelist',
  },
  refills: {
    mainUrl: BASE_URL + '/app/partner/refills',
  },
  pharmacy: {
    mainUrl: BASE_URL + '/app/partner/pharmacy',
  },
  trackingInfo: {
    mainUrl: BASE_URL + '/app/partner/statusTracking',
  },
  utilizationReport: {
    mainUrl: BASE_URL + '/app/partner/reports',
  },
};

//removed modules.exports for Typescript due to --isolatedModules error
export { endpoints, BASE_URL };
