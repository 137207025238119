import { Modal } from 'react-bootstrap';
import { NairaSymbol } from '../../utils/mappings';
import { dosageForm, frequencyList } from '../../utils/drugUtils';
import Select, { createFilter } from 'react-select';
import { useCallback, useEffect, useState } from 'react';
import { IDrugs } from '../../interfaces/FulfilmentInterfaces';
import { isFormValidated } from '../../utils/formUtils';
import { addToast } from '../../utils/toastNotifications';
import { IPricelist } from '../../interfaces/PricelistInterface';

const DrugModal = ({
  close,
  show,
  addDrugsToPrescription,
  showSortingCenter,
}: {
  show: boolean;
  close: Function;
  addDrugsToPrescription: Function;
  showSortingCenter: boolean;
}) => {
  const [showMore, setShowMore] = useState(false);
  const initialDrugData: IDrugs = {
    id: 0,
    drugId: 0,
    name: '',
    form: '',
    frequency: '',
    strength: '',
    duration: '',
    comments: '',
    drugPrice: 0,
    unitPrice: 0,
  };
  const [drugDuration, setDrugDuration] = useState({
    unit: 1,
    total: 7,
  });
  const [drugData, setDrugData] = useState(initialDrugData);
  const [selectDrugList, setSelectDrugList] = useState<any[]>([]);

  // Drug List related methods
  const handleDrugChange = (input: string) => (event: any) => {
    setDrugData({
      ...drugData,
      [input]: event.target.value,
    });

    if (input === 'name') {
      if (event.target.value === '') {
        setShowMore(false);
      }
    }
  };

  const clickShowMore = () => {
    setShowMore(true);
    setDrugData({
      ...drugData,
      name: '',
    });
  };

  // react select onChange
  const drugChange = (selectedValue: any) => {
    setDrugData({
      ...drugData,
      name: selectedValue.value,
      drugPrice: selectedValue.drugPrice,
      unitPrice: selectedValue.unitPrice,
    });
  };

  const handleDurationChange = (input: string) => (event: any) => {
    setDrugDuration({
      ...drugDuration,
      [input]: event.target.value,
    });
  };
  const clearDrugForm = (event: any) => {
    event.preventDefault();
    setDrugData(initialDrugData);
  };

  const addToPrescription = (event: any) => {
    if (!isFormValidated('drug-form')) {
      addToast('Please fill all fields', 'warning');
      return;
    }
    event.preventDefault();

    drugData.duration = `${drugDuration.unit}/${drugDuration.total}`;
    //add
    addDrugsToPrescription(drugData);
    setDrugData(initialDrugData);

    addToast('Drug added', 'success');

    setShowMore(false);
  };

  const loadDrugs = useCallback(() => {
    //load from localStorage if available, else load from Api and store in local

    let savedPricelist = localStorage.getItem('druglist');

    if (savedPricelist === null || typeof savedPricelist === 'undefined') {
      addToast('Pricelist could not be loaded');
      return;
    }

    let parsedPricelist = JSON.parse(savedPricelist) as IPricelist[];

    //if the user has selected to use sorting center, show only sorting center drugs
    if (showSortingCenter) {
      parsedPricelist = parsedPricelist.filter((x) => x.isPartnerPrice === false);
    }
    const drugArray = parsedPricelist.map((drug: any, index: any) => {
      return {
        id: index,
        label: drug.drugName,
        value: drug.drugName,
        drugPrice: drug.drugPrice,
        unitPrice: drug.unitPrice,
      };
    });
    setSelectDrugList(drugArray);
    return;
  }, [showSortingCenter]);

  useEffect(() => {
    if (show) {
      loadDrugs();
    }
  }, [show, loadDrugs]);

  return (
    <Modal show={show} onHide={() => close()} centered>
      <Modal.Header className='bg-green' closeButton>
        <Modal.Title style={{ color: '#fff' }}>Add Medication</Modal.Title>
      </Modal.Header>
      <form id='drug-form' className='bg-green-light'>
        <Modal.Body>
          <div className={showMore ? `form-group d-none` : 'form-group d-block'}>
            <label>
              Drug name<span className='text-danger'>*</span>
            </label>
            <Select
              options={selectDrugList}
              filterOption={createFilter({ ignoreAccents: false })}
              onChange={drugChange}
              noOptionsMessage={() => (
                <button className='btn' onClick={clickShowMore}>
                  Drug Not Found. Click to Type Drug
                </button>
              )}
            />
            <div>
              Price: {NairaSymbol}
              {drugData.drugPrice.toLocaleString()}
            </div>
          </div>
          <div className={showMore ? `form-group d-block` : 'form-group d-none'}>
            <label>
              Drug Name<span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              required
              className='form-control'
              placeholder='Input Drug Name...'
              value={drugData.name}
              onChange={handleDrugChange('name')}
              list='drugList'
            />
          </div>
          <div className='form-group'>
            <label>
              Dosage form<span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              required
              className='form-control'
              placeholder='e.g Tabs, IV'
              value={drugData.form}
              onChange={handleDrugChange('form')}
              list='dosage-list'
            />
            <datalist id='dosage-list'>
              {dosageForm.map((dosage, key) => (
                <option key={key}>{dosage}</option>
              ))}
            </datalist>
          </div>
          <div className='form-group'>
            <label>
              Strength<span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              required
              className='form-control'
              placeholder='e.g 500mg, 20ml'
              value={drugData.strength}
              onChange={handleDrugChange('strength')}
            />
          </div>
          <div className='form-group'>
            <label>
              Frequency<span className='text-danger'>*</span>
            </label>
            <input
              type='text'
              required
              list='frequencylist'
              className='form-control'
              placeholder='e.g tds, bds, daily, nocte'
              value={drugData.frequency}
              onChange={handleDrugChange('frequency')}
            />
            <datalist id='frequencylist'>
              {frequencyList?.map((drugFrequency, key) => (
                <option key={key}>{drugFrequency}</option>
              ))}
            </datalist>
          </div>
          <div className='form-group'>
            <label>
              Duration <span className='text-muted'>e.g 2 days, 3 weeks</span>
              <span className='text-danger'>*</span>
            </label>

            <div className='row'>
              <div className='col-4'>
                <input
                  type='number'
                  required
                  max={120}
                  min={1}
                  className='form-control'
                  // placeholder="e.g 2/7, 3/12"
                  value={drugDuration.unit}
                  onChange={handleDurationChange('unit')}
                />
              </div>
              <div className='col-6'>
                <select
                  required
                  className='custom-select'
                  value={drugDuration.total}
                  onChange={handleDurationChange('total')}
                >
                  <option value='7'>Days</option>
                  <option value='52'>Weeks</option>
                  <option value='12'>Months</option>
                </select>
                {/* <input
                  type="text"
                  required
                  className="form-control"
                  //placeholder="e.g 2/7, 3/12"
                  value={drugDuration.total}
                  onChange={handleDurationChange("total")}
                /> */}
              </div>
            </div>
          </div>
          <div className='form-group'>
            <label>Notes</label>
            <textarea
              className='form-control'
              placeholder='e.g Before meals'
              value={drugData.comments}
              onChange={handleDrugChange('comments')}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className='btn btn-warning' onClick={clearDrugForm}>
            Clear
          </button>
          <button
            className='btn btn-primary bg-green'
            type='submit'
            onClick={addToPrescription}
            // style={{ backgroundColor: '#1489C8' }}
          >
            Add to fulfilment
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DrugModal;
