import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useOutsideAlerter } from './OutsideClick';
import { IFulfilmentPatientData } from '../../interfaces/FulfilmentInterfaces';
import { IPatientSearchBar } from '../../interfaces/AppInterfaces';
import { endpoints } from '../../utils/URLs';

const PatientSearch = (props: IPatientSearchBar) => {
  const [patientSearchResult, setPatientSearchResult] = useState<IFulfilmentPatientData[]>([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  let timer: NodeJS.Timeout;
  const axiosInstance = axios.create();

  const hideSearchResult = (event: any) => {
    setShowSearch(false);
  };

  const inputFocus = () => {
    setShowSearch(true);
  };

  /*To handle when we clck outside the search */

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, hideSearchResult);

  /* end click outside */

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputSearch = event.target.value;
    setSearchTerm(inputSearch);
    // if (inputSearch.length < 3) {
    //   return;
    // }
    // searchPatient(inputSearch);
  };
  const clearTimer = () => {
    clearTimeout(timer);
  };

  const startTimer = (event: any) => {
    clearTimeout(timer);
    let searchTerm = event.target.value;
    timer = setTimeout(() => searchPatient(searchTerm), 2000);
  };

  const searchPatient = async (searchTerm: string) => {
    if (searchTerm.length < 3) {
      return;
    }

    // if (props.partnerCode === null || props.partnerCode === '') {
    //   addToast('Please select a partner');
    //   return;
    // }

    //show loading
    const loading: IFulfilmentPatientData = {
      patientId: 0,
      firstName: 'Loading...',
      phoneNumber: '',
      lastName: '',
      hmoId: '',
      pharmacyCode: '',
      pharmacyName: '',
      email: '',
      gender: 'Others',
      address: '',
      locationLatitude: '',
      locationLongitude: '',
      fulfilmentService: 'Telemedicine',
    };
    const loadingArray = [loading];
    setPatientSearchResult(loadingArray);
    setShowSearch(true);

    //callback to notify caller that search is ongoing
    if (props.searchFeedback) {
      props.searchFeedback(true);
    }

    // const url = `${endpoints.FulfilmentPatients.mainUrl}/${pharmacyId}/${searchTerm}`;
    const url = `${endpoints.fulfilmentPatients.mainUrl}/search?phoneOrHmoId=${searchTerm}&partnerCode=${props.partnerCode}`;
    try {
      const response = await axiosInstance.get(url); //use a new instance of axios to avoid showing full screen loading

      const patientData = response.data as IFulfilmentPatientData[];
      setPatientSearchResult(patientData);

      //if no result, trigger callback with undefined, so calling component can handle
      if (patientData.length === 0 && props.callback) {
        props.callback(searchTerm);
      }
    } catch (error) {
      console.error(error);
    } finally {
      if (props.searchFeedback) {
        props.searchFeedback(false);
      }
    }
  };

  const selectPatient = (selectedPatient: IFulfilmentPatientData) => {
    setSearchTerm(searchTerm);
    setShowSearch(false);

    if (selectedPatient.patientId === 0) {
      return;
    }

    //callback set, call the callback passing the selected patient data
    if (props.callback) {
      props.callback(selectedPatient.hmoId, selectedPatient);
    }
  };

  return (
    <div className='form-group ' ref={wrapperRef} onFocus={inputFocus}>
      {/* onFocus={toggleSearchResult} onBlur={toggleSearchResult}> */}
      <span className='bi bi-search search-icon'></span>
      <input
        type='text'
        className={
          props.inputStyle ? 'form-control pl-5 pr-4 ' + props.inputStyle : 'form-control pl-5 pr-4'
        }
        placeholder='Search. Start typing HMO ID or phone number to select'
        onChange={handleChange}
        value={searchTerm}
        onKeyUp={startTimer}
        onKeyDown={clearTimer}
      />
      {showSearch && patientSearchResult.length > 0 ? (
        <div className='search-result-panel'>
          <ul style={{ marginLeft: '-30px', backgroundColor: 'white' }}>
            {patientSearchResult.map((patient) => (
              <li
                key={patient.patientId}
                className='search-result'
                onClick={() => selectPatient(patient)}
              >
                {patient.firstName} {patient.lastName} - {patient.hmoId}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PatientSearch;
