import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';
import {
  IFulfilmentData,
  IDrugs,
  initialFulfilmentData,
  IFulfilmentPatientData,
} from '../../interfaces/FulfilmentInterfaces';
import { FulfilmentService, Gender } from '../../utils/mappings';
import { addToast } from '../../utils/toastNotifications';
import { isFormValidated } from '../../utils/formUtils';
import { endpoints } from '../../utils/URLs';
import { usePlacesWidget } from 'react-google-autocomplete';
import { IAddressComponent, IPharmacyList } from '../../interfaces/PharmacyInterface';
import PharmacySideModal from './PharmacySideModal';
import DrugModal from './DrugModal';
import { IPricelist } from '../../interfaces/PricelistInterface';
import { Modal } from 'react-bootstrap';
import PatientSearch from '../common/PatientSearch';

const NewFulfilment = () => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [fulfilmentData, setData] = useState<IFulfilmentData>(initialFulfilmentData);
  const [selectDrugList, setSelectDrugList] = useState<any[]>();
  const [showPharmacyModal, setShowPharmacyModal] = useState(false);
  const [modalData, setModalData] = useState<IPharmacyList[]>([]);
  const [nextLevel, setNextLevel] = useState('');
  const [addressInfo, setAddressInfo] = useState<IAddressComponent | undefined>();
  const [selectedPharmacy, setSelectedPharmacy] = useState<IPharmacyList | undefined>();
  const [showSortingCenterMeds, setShowSortingCenterMeds] = useState(false);
  const [showAnnouncement, setShowAnnouncement] = useState(true);
  const [patientFound, setPatientFound] = useState(false);
  const [isSearching, setIsSearching] = useState(true);
  const [selectedPatient, setSelectedPatient] = useState<IFulfilmentPatientData | undefined>();
  const [editInfo, setEditInfo] = useState(false);

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: (place: any) => {
      //reset selected pharmacy
      setSelectedPharmacy(undefined);

      const selectedAddress = place.address_components;

      if (selectedAddress === null || typeof selectedAddress === 'undefined') {
        return;
      }

      const fullAddress = `${place!.name} ${place!.formatted_address}`;

      //reset to defaults
      let addressInformation: IAddressComponent = {
        plus_code: '',
        street_number: '', //street number
        route: '', // street name
        locality: '', //Name of big town/City e.g Lagos
        administrative_area_level_1: '', //State
        administrative_area_level_2: '', //LGA
        sublocality_level_1: '', //Area
        neighborhood: '', //Area
        country: '', //Country
        postal_code: '', //Postal code
        colloquial_area: '', //Area
        fullAddress: fullAddress,
      };

      for (const component of place.address_components) {
        const addressType = component.types[0] as string;

        //if proprerty exist
        if (addressType in addressInformation) {
          const addressValue = component['long_name'] as string;

          //https://stackoverflow.com/a/62438434/2929906
          let addressKey = addressType as keyof typeof addressInformation;
          addressInformation[addressKey] = addressValue;
        }
      }

      //https://reactjs.org/docs/hooks-reference.html#functional-updates
      //without using functional updates here, a normal set state resets the entire object state
      setData((x) => {
        return { ...x, enrolleeAddress: fullAddress };
      });

      setModalData([]);
      filterPharmacy(addressInformation);
    },
    options: {
      types: [],
      //fields: ['address_component', 'geometry.location', 'formatted_address'],
      fields: ['address_component', 'formatted_address', 'name'],
      componentRestrictions: { country: 'ng' },
    },
  });

  const filterPharmacy = (addressInformation: IAddressComponent, searchLevel?: string) => {
    if (addressInformation == null || typeof addressInformation === 'undefined') {
      return;
    }

    setModalData([]);
    setSelectedPharmacy(undefined);
    setAddressInfo(addressInformation);
    setModalData([]);

    const savedPharmacyList = sessionStorage.getItem('pharmacy-list');
    if (!savedPharmacyList || savedPharmacyList === null) {
      return;
    }

    const parsedList = JSON.parse(savedPharmacyList) as IPharmacyList[];

    //get everything in the state of residence
    let stateOfPremise = addressInformation!.administrative_area_level_1
      .trim()
      .toLowerCase()
      .replace('state', '');

    const allPharmacies = parsedList.filter(
      (x) => x.state.toLowerCase().trim() === stateOfPremise && x.address !== null
    );

    if (allPharmacies.length === 0) {
      return;
    }

    const area =
      addressInformation?.neighborhood.trim().toLowerCase() ||
      addressInformation?.sublocality_level_1.trim().toLowerCase() ||
      addressInformation?.colloquial_area.trim().toLowerCase();

    const lga =
      addressInformation?.administrative_area_level_2.trim().toLowerCase() ||
      addressInformation?.locality.trim().toLowerCase();

    const fulfilmentService = document.getElementById('fulfilment-service') as HTMLSelectElement;

    const showSortingCenter =
      stateOfPremise.includes('lagos') && fulfilmentService.value.toLowerCase() === 'chronic';

    setData((x) => {
      return { ...x, showSortingCenter: showSortingCenter };
    });

    //if search level is Lga
    if (searchLevel === 'lga') {
      const allPharmaciesInLga = allPharmacies.filter((x) =>
        x.address.trim().toLowerCase().includes(lga)
      );

      setModalData(allPharmaciesInLga);
      setShowPharmacyModal(true);
      setNextLevel('state');
      return;
    }

    //if search level is state
    if (searchLevel === 'state') {
      setModalData(allPharmacies);
      setShowPharmacyModal(true);
      setNextLevel('');
      return;
    }

    //no search level, means we filter normally
    let pharmaciesInArea = allPharmacies.filter((x) =>
      x.address.trim().toLowerCase().includes(area)
    );

    //if found, show
    if (pharmaciesInArea.length > 0) {
      setModalData(pharmaciesInArea);
      setShowPharmacyModal(true);
      setNextLevel('lga');
      return;
    }
    //if nothing in area,

    const allPharmaciesInLga = allPharmacies.filter((x) =>
      x.address.trim().toLowerCase().includes(lga)
    );

    if (allPharmaciesInLga.length > 0) {
      setModalData(allPharmaciesInLga);
      setShowPharmacyModal(true);
      setNextLevel('state');
      return;
    }

    setModalData(allPharmacies);
    setShowPharmacyModal(true);
    setNextLevel('');
  };

  const modalFilter = (searchLevel?: string) => {
    filterPharmacy(addressInfo!, searchLevel);
  };

  const setModalPharmacy = (pharmacy: IPharmacyList, isSortingCenter: boolean) => {
    setShowSortingCenterMeds(isSortingCenter);
    setSelectedPharmacy(pharmacy);
    setShowPharmacyModal(false);
  };

  const closeSideModal = () => {
    setShowPharmacyModal(false);
  };

  /* Handlers */

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (event: any) => {
    event.preventDefault();
    setShow(true);
  };

  const handleChange = (input: string) => (event: any) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

    // setData({
    //   ...fulfilmentData,
    //   [input]: value,
    // });

    //if we are editing address for selected patient that has no address
    if (input === 'enrolleeAddress' && selectedPatient) {
      setEditInfo(true); //so we can save this as part of the patient info
    }

    //used this because of the map component
    setData((x) => {
      return { ...x, [input]: value };
    });
  };

  const selectPatient = (searchTerm: string, patient?: IFulfilmentPatientData) => {
    if (!patient) {
      //no patient found, handle
      // addToast('Patient with the HMO ID or Phone number not found', 'error');
      setPatientFound(false);
      setEditInfo(true);
      setSelectedPatient(patient);
      setData(initialFulfilmentData);
      return;
    }
    setEditInfo(false);
    setPatientFound(true);
    setSelectedPatient(patient);
    setData({
      ...fulfilmentData,
      enrolleeFirstName: patient.firstName,
      enrolleeLastName: patient.lastName,
      enrolleePhone: patient.phoneNumber,
      enrolleeEmail: patient.email,
      enrollmentCode: patient.hmoId,
      enrolleeAddress: patient.address,
      enrolleeGender: patient.gender,
    });
  };

  const searchFeedback = (searching: boolean) => {
    setIsSearching(searching);
  };

  const savePrescription = async (event: any) => {
    if (!isFormValidated('new-prescription')) {
      return;
    }
    event.preventDefault();

    if (fulfilmentData.drugs.length === 0) {
      addToast('Add at least one medication', 'warning');
      return;
    }

    if (selectedPharmacy && selectedPharmacy !== null) {
      fulfilmentData.pharmacyCode = selectedPharmacy.pharmacyCode;
      fulfilmentData.pharmacyAddress = selectedPharmacy.address;
    }

    const url = endpoints.fulfilments.mainUrl;

    setButtonLoading(true);

    try {
      await axios.post(url, fulfilmentData);

      //if the patient info has been edited, save patient info
      if (editInfo) {
        await savePatientInfo();
      }
      clearForm();
      addToast('Prescription saved successfully', 'success');
    } catch (error) {
      console.error(error);
    } finally {
      setButtonLoading(false);
    }
  };

  const clearForm = () => {
    setData(initialFulfilmentData);
    fulfilmentData.drugs.length = 0;
    setEditInfo(true);
    setSelectedPatient(undefined);
  };

  const changePharmacy = () => {
    setSelectedPharmacy(undefined);
    setShowPharmacyModal(true);
  };

  //Methods to load drug prices
  //checks the last update to see if tariff is update for partner
  const setLastUpdate = useCallback(async () => {
    const lastUpdateVersion = `druglist-lastupdate`;

    try {
      const response = await axios.get(`${endpoints.pricelist.mainUrl}/lastupdate`);
      localStorage.setItem(lastUpdateVersion, response.data);
    } catch (error: any) {
      addToast('', 'error');
    }
  }, []);

  //checks the last update to see if tariff is update for partner
  const getLastUpdate = useCallback(async () => {
    const lastUpdateVersion = `druglist-lastupdate`;
    const savePriceListVersion = localStorage.getItem(lastUpdateVersion);
    //if no version set, get pricelist
    if (typeof savePriceListVersion === 'undefined' || savePriceListVersion === null) {
      return true;
    }

    let lastupdateValue = new Date().toISOString();
    try {
      const response = await axios.get(`${endpoints.pricelist.mainUrl}/lastupdate`);
      lastupdateValue = response.data;
      localStorage.setItem(lastUpdateVersion, response.data);
    } catch (error: any) {
      addToast('', 'error');
    }
    const previousUpdate = new Date(savePriceListVersion!);
    const lastUpdate = new Date(lastupdateValue);

    if (lastUpdate > previousUpdate) {
      return true;
    }

    return false;
  }, []);

  const loadFromRemote = useCallback(async () => {
    try {
      const url = `${endpoints.pricelist.mainUrl}`;

      const response = await axios.get(url);
      localStorage.setItem('druglist', JSON.stringify(response.data.data));

      const parsedData = response.data.data as IPricelist[];

      const drugArray = parsedData?.map((drug, index) => {
        return {
          id: drug.priceListId,
          label: drug.drugName,
          value: drug.drugName,
          drugPrice: drug.drugPrice,
          unitPrice: drug.unitPrice,
        };
      });
      setSelectDrugList(drugArray);
      await setLastUpdate();
    } catch (error) {
      //display error. ask them to reload. disable button
      addToast(
        'An error occured and the drug list could not be loaded. Please reload this page',
        'error',
        false
      );
      console.error(error);
    }
  }, [setLastUpdate]);

  const loadDrugs = useCallback(async () => {
    //load from localStorage if available, else load from Api and store in local

    let savedDrugs = localStorage.getItem('druglist');
    if (savedDrugs && savedDrugs !== 'undefined') {
      const isNewVersionAvailable = await getLastUpdate();
      if (isNewVersionAvailable) {
        await loadFromRemote();
        return;
      } else {
        const parsedDrugs = JSON.parse(savedDrugs) as IPricelist[];
        const drugArray = parsedDrugs.map((drug: any, index: any) => {
          return {
            id: index,
            label: drug.drugName,
            value: drug.drugName,
            drugPrice: drug.drugPrice,
            unitPrice: drug.unitPrice,
          };
        });
        setSelectDrugList(drugArray);
        return;
      }
    }
    loadFromRemote();
  }, [getLastUpdate, loadFromRemote]);

  const addDrugToPrescription = (drug: IDrugs) => {
    let updatedDrugs = fulfilmentData.drugs;

    drug.id = updatedDrugs.length + 1;

    updatedDrugs.push(drug);

    setData({
      ...fulfilmentData,
      drugs: updatedDrugs,
    });
  };

  const deleteDrug = (id: number) => (event: any) => {
    event.preventDefault();

    let response = window.confirm('Delete this medication ?');
    if (!response) {
      return;
    }

    let updatedDrugs = fulfilmentData.drugs.filter((x) => x.id !== id);
    setData({
      ...fulfilmentData,
      drugs: updatedDrugs,
    });
  };

  //Patient modal methods

  const editPatientInfo = () => {
    setEditInfo(true);
  };

  const savePatientInfo = async () => {
    const patientInfo: IFulfilmentPatientData = {
      patientId: selectedPatient ? selectedPatient.patientId : 0,
      firstName: fulfilmentData.enrolleeFirstName,
      lastName: fulfilmentData.enrolleeLastName,
      phoneNumber: fulfilmentData.enrolleePhone,
      hmoId: fulfilmentData.enrollmentCode,
      email: fulfilmentData.enrolleeEmail,
      gender: fulfilmentData.enrolleeGender,
      address: fulfilmentData.enrolleeAddress,
      pharmacyCode: '',
      pharmacyName: '',
      fulfilmentService: fulfilmentData.fulfilmentService,
      locationLatitude: '',
      locationLongitude: '',
    };

    const url = `${endpoints.fulfilmentPatients.mainUrl}`;

    setButtonLoading(true);
    try {
      await axios.put(url, patientInfo);
    } catch (error) {
      console.error(error);
    } finally {
      setButtonLoading(false);
    }
  };

  // const calculateDrugPrice = (drug: IDrugs) => {
  //   //get the frequency
  //   let frequency = 1;
  //   switch (drug.frequency.toLowerCase()) {
  //     case 'daily':
  //     case 'nocte':
  //     case 'once daily':
  //     case 'At Night':
  //       frequency = 1;
  //       break;
  //     case 'bd':
  //     case '12 hourly':
  //       frequency = 2;
  //       break;
  //     case 'tds':
  //     case '8 hourly':
  //       frequency = 3;
  //       break;
  //     case '6 hourly':
  //       frequency = 4;
  //       break;
  //     default:
  //       frequency = 1;
  //       break;
  //   }
  //   //get the number of days
  //   let numberOfDays = 1;
  //   const durationParts = drug.duration.split('/');
  //   const unit = durationParts[0];
  //   const total = durationParts[1];
  //   switch (total) {
  //     case '7':
  //       numberOfDays = parseInt(unit);
  //       break;
  //     case '52':
  //       numberOfDays = parseInt(unit) * 7;
  //       break;
  //     case '12':
  //       numberOfDays = parseInt(unit) * 30;
  //       break;
  //     default:
  //       numberOfDays = 7;
  //       break;
  //   }

  //   //units of drugs needed
  //   //const drugUnits = frequency * numberOfDays;

  //   const drugPrice = (frequency * numberOfDays * drug.unitPrice).toFixed(2);

  //   return parseFloat(drugPrice);
  // };

  useEffect(() => {
    loadDrugs();
  }, [loadDrugs]);

  const featureAnnoucement = (
    <Modal show={showAnnouncement} onHide={() => setShowAnnouncement(false)}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: '#1489C8', textAlign: 'center' }}>
          Introducing WSC in Lagos
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='text-center'>
        <img
          src={require('../../assets/images/wsc.svg').default}
          className='img-fluid'
          style={{ width: '50%' }}
          alt='announcement'
        />
        <div className='mt-3 fw-light'>
          Get <strong className='fw-bold'>cheaper chronic medications</strong> and
          <strong className='fw-bold ml-1'>FREE Next Day Delivery</strong> to any location in
          <strong className='fw-bold ml-1'>Lagos</strong>
        </div>
        <div className='mt-3 fw-light'>
          Select
          <strong className='ml-1'>WSC Lagos</strong> when choosing a pharmacy and get better prices
          and free delivery
        </div>
      </Modal.Body>

      <Modal.Footer></Modal.Footer>
    </Modal>
  );

  let drugModal = (
    <DrugModal
      show={show}
      close={handleClose}
      addDrugsToPrescription={addDrugToPrescription}
      showSortingCenter={showSortingCenterMeds}
    />
  );

  let pharmacyModal = (
    <PharmacySideModal
      showModal={showPharmacyModal}
      close={closeSideModal}
      modalData={modalData}
      setModalPharmacy={setModalPharmacy}
      nextLevel={nextLevel}
      filter={modalFilter}
      showSortingCenter={fulfilmentData.showSortingCenter}
    />
  );

  return (
    <div>
      {featureAnnoucement}
      <h5 className='text-center' style={{ color: '#1489C8' }}>
        Create a new fulfilment
      </h5>
      <div className='row justify-content-center'>
        <div
          className='col-lg-6 col-md-10 col-sm-10'
          style={{
            marginTop: '30px',
            backgroundColor: '#fff',
            padding: '20px',
          }}
        >
          <form id='new-prescription'>
            <fieldset disabled={buttonLoading}>
              <div className='form-group row'>
                <div className='col-md-12'>
                  <label className='form-label'>Enter HMO ID or phone number</label>
                  <PatientSearch
                    partnerCode={''}
                    callback={selectPatient}
                    searchFeedback={searchFeedback}
                  />
                </div>
              </div>
              <div className={!isSearching || patientFound ? 'mt-2' : 'd-none mt-2'}>
                <div className={selectedPatient ? 'row' : 'row d-none'}>
                  <div className='col-12'>
                    <button
                      disabled={false}
                      type='button'
                      className='float-right btn btn-sm btn-outline-success'
                      onClick={editPatientInfo}
                    >
                      Edit
                      <span className='ml-2 bi bi-pencil'></span>
                    </button>
                  </div>
                </div>
                <fieldset disabled={!editInfo} className='row mt-1'>
                  <div className='form-group col-sm-6'>
                    <label>
                      Patient first name <span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      required
                      value={fulfilmentData.enrolleeFirstName}
                      onChange={handleChange('enrolleeFirstName')}
                    />
                  </div>
                  <div className='form-group col-sm-6'>
                    <label>
                      Patient last name <span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      required
                      value={fulfilmentData.enrolleeLastName}
                      onChange={handleChange('enrolleeLastName')}
                    />
                  </div>

                  <div className='form-group col-sm-6 '>
                    <label>
                      HMO Id / Prescription Code
                      <span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      required
                      value={fulfilmentData.enrollmentCode}
                      onChange={handleChange('enrollmentCode')}
                    />
                  </div>
                  <div className='form-group col-sm-6'>
                    <label>
                      Patient Phone number<span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      value={fulfilmentData.enrolleePhone}
                      onChange={handleChange('enrolleePhone')}
                      maxLength={13}
                      placeholder='e.g 080.... or 234...'
                      required
                      pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                    />
                  </div>

                  <div className='form-group col-6'>
                    <label>Gender</label>
                    <select
                      className='custom-select'
                      value={fulfilmentData.enrolleeGender}
                      onChange={handleChange('enrolleeGender')}
                    >
                      {Gender.map((gender) => (
                        <option key={gender.key}>{gender.value}</option>
                      ))}
                    </select>
                  </div>
                  <div className='form-group col-6'>
                    <label>Patient Email Address</label>
                    <input
                      type='text'
                      className='form-control'
                      value={fulfilmentData.enrolleeEmail}
                      onChange={handleChange('enrolleeEmail')}
                    />
                  </div>
                </fieldset>
                <div className='row'>
                  <div className='form-group col-12'>
                    <label>
                      Patient location <span className='text-danger'>*</span>
                    </label>
                    <input
                      type='text'
                      id='address'
                      className='form-control'
                      disabled={!editInfo && selectedPatient?.address !== ''}
                      required
                      value={fulfilmentData.enrolleeAddress}
                      onChange={handleChange('enrolleeAddress')}
                      ref={ref as unknown as React.LegacyRef<HTMLInputElement>}
                    />
                  </div>
                </div>
              </div>
              {/* <div className={!isSearching && patientFound ? 'row mt-2' : 'row d-none '}>
                <div className='col-12'>
                  <button
                    type='button'
                    className='float-right btn btn-sm btn-outline-success'
                    onClick={() => setShowPatientModal(true)}
                  >
                    Update Personal Info
                  </button>
                </div>
              </div> */}
              <h6 className='mt-4'>Fulfilment information</h6>
              <div className='form-group row mt-3'>
                <div className='col-sm-6'>
                  <label>PA Code</label>
                  <input
                    type='text'
                    className='form-control'
                    value={fulfilmentData.preAuthorizationCode}
                    onChange={handleChange('preAuthorizationCode')}
                  />
                </div>
                <div className='col-6'>
                  <label>
                    Service Type <span className='text-danger'>*</span>
                  </label>
                  <select
                    className='custom-select'
                    id='fulfilment-service'
                    value={fulfilmentData.fulfilmentService}
                    onChange={handleChange('fulfilmentService')}
                  >
                    {FulfilmentService.map((service, key) => (
                      <option key={key}>{service.value}</option>
                    ))}
                  </select>
                </div>
              </div>

              {selectedPharmacy !== null && typeof selectedPharmacy !== 'undefined' ? (
                <div className='form-group'>
                  <label>Pharmacy</label>
                  <div className='card p-3 card-shadow'>
                    <div className='fw-bold d-flex justify-content-between align-items-center'>
                      {selectedPharmacy?.pharmacyName}

                      <button className='btn btn-default float-right' onClick={changePharmacy}>
                        <i className='bi bi-x-lg text-danger font-bold' title='Remove'></i>
                      </button>
                    </div>
                    <div className='mt-2'>{selectedPharmacy?.address}</div>
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className='form-group row'>
                <div className='col-md-12'>
                  <label>
                    Diagnosis <span className='text-danger'>*</span>
                  </label>
                  <input
                    className='form-control'
                    required
                    value={fulfilmentData.diagnosis}
                    onChange={handleChange('diagnosis')}
                  />
                </div>
                <div className='col-12 mt-3'>
                  <label htmlFor='delivery-check'>
                    Check this box to have this order delivered
                  </label>
                  <input
                    id='delivery-check'
                    type='checkbox'
                    className='ml-3'
                    checked={fulfilmentData.isDelivery}
                    onChange={handleChange('isDelivery')}
                  />
                </div>
              </div>
              <h5>Medications</h5>
              <div className='table-responsive'>
                <table className='table'>
                  <tbody>
                    {fulfilmentData.drugs.map((drug, key) => (
                      <tr key={key}>
                        <td>{drug.id}</td>
                        <td>{drug.name}</td>
                        <td>
                          {`${drug.form}, ${drug.strength}, ${drug.frequency}
                          ${drug.duration}`}
                        </td>

                        <td>
                          <button
                            className='btn'
                            style={{ marginTop: '-7px' }}
                            onClick={deleteDrug(drug.id)}
                          >
                            <i className='bi-trash text-danger' style={{ width: '1rem' }}></i>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <hr />
              <div style={{ marginBottom: '10px' }}>
                <button
                  className='btn btn-info btn-sm bg-blue'
                  onClick={handleShow}
                  disabled={selectDrugList?.length === 0}
                >
                  Add Medication +
                </button>
              </div>
              <div className='form-group mt-4'>
                <label>Notes</label>
                <textarea
                  className='form-control'
                  value={fulfilmentData.notes}
                  onChange={handleChange('notes')}
                ></textarea>
              </div>
              <br />
              <div>
                <button
                  type='submit'
                  className='btn btn-block request-btn-white bg-green'
                  onClick={savePrescription}
                >
                  Send Fulfilment request
                  {buttonLoading ? (
                    <span
                      className='spinner-border spinner-border-sm btn-spinner'
                      role='status'
                      aria-hidden='true'
                    ></span>
                  ) : (
                    ''
                  )}
                </button>
              </div>
              <br />
              {drugModal}
              {pharmacyModal}
            </fieldset>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewFulfilment;
