import { IFulfilmentResponse, IFulfilmentSummary } from '../../interfaces/FulfilmentInterfaces';
import { IProps } from '../../interfaces/AppInterfaces';
import { endpoints } from '../../utils/URLs';
import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import PaginationBar from '../common/Pagination';
import { addToast } from '../../utils/toastNotifications';
import { Link } from 'react-router-dom';
import ButtonLoader from '../common/ButtonLoader';
import { exportToCSV } from '../../utils/csvDownloadUtil';
import FulfilmentSideModal from './FulfilmentSideModal';
import { useOutsideAlerter } from '../common/OutsideClick';
import { timeOfDayGreeting } from '../../utils/dateTimeUtils';
import RequestStatusTrackingSidebar from './RequestStatusTrackingSidebar';

const FulfilmentsView = (props: IProps): JSX.Element => {
  // const history = useHistory();

  //https://stackoverflow.com/a/39672914/2929906
  const [pageInfo, setPageInfo] = useState({
    pageIndex: 1,
    pageCount: 1,
  });
  const [showPagination, setShowPagination] = useState(false);

  const [prescriptionData, setPrescriptionData] = useState<IFulfilmentResponse[]>([]);
  const [allData, setAllData] = useState<IFulfilmentResponse[]>([]); //to hold all table result in unfiltered format
  const [fulfilmentStatus, setFulfilmentStatus] = useState<string>();
  const [isSearch, setIsSearch] = useState(false);
  const [advSearch, setAdvSearch] = useState(false);
  const [showSideModal, setShowSideModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedData, setSelectedData] = useState<IFulfilmentResponse | undefined>();
  const [fulfilmentSummary, setFulfilmentSummary] = useState<IFulfilmentSummary | undefined>();

  const formatFulfilmentStatus = (fulfilmentStatus: string) => {
    switch (fulfilmentStatus) {
      case 'Dispensed':
      case 'Approved':
      case 'Completed':
        return 'Dispensed';
      default:
        return fulfilmentStatus;
    }
  };

  const getPharmacies = async () => {
    const url = endpoints.pharmacy.mainUrl;

    try {
      const response = await axios.get(url);
      const data = response.data.data;
      sessionStorage.setItem('pharmacy-list', JSON.stringify(data));
    } catch (error) {
      addToast('Error occured fetching pharmacies', 'error');
    }
  };

  const loadFulfilments = useCallback(
    async (pageIndex: number = 1, pageSize: number = 50) => {
      let url = `${endpoints.fulfilments.mainUrl}?pageIndex=${pageIndex}&pageSize=${pageSize}`;
      if (fulfilmentStatus) {
        url += `&fulfilmentStatus=${fulfilmentStatus}`;
      }
      try {
        const response = await axios.get(url);

        setPrescriptionData(response.data.data);
        setAllData(response.data.data);

        let newPageInfo = {
          pageIndex: response.data.pageIndex,
          pageCount: response.data.pageCount,
        };
        setPageInfo(newPageInfo);
        setShowPagination(true);
      } catch (error) {
        addToast('Network error occured', 'error');
        console.error(error);
      }
    },
    [fulfilmentStatus]
  );

  const searchTable = (event: any) => {
    //search loaded data
    let searchParam = event.target.value;

    if (searchParam === '') {
      setPrescriptionData(allData);
      setAdvSearch(false);
      setShowPagination(true);
      return;
    }

    //if not found, search remote?
    setShowPagination(false);

    searchParam = searchParam.toLowerCase();
    const searchResult = allData.filter(
      (x) =>
        x.enrolleeFirstName?.toLowerCase().includes(searchParam) ||
        x.enrolleeLastName?.toLowerCase().includes(searchParam) ||
        x.enrolleePhone?.toLowerCase().includes(searchParam) ||
        x.enrollmentCode?.toLowerCase().includes(searchParam)
    );

    // //set table data to search result
    setPrescriptionData(searchResult);

    if (searchParam.length >= 3) {
      setAdvSearch(true);
    } else {
      //setIsLoading(false);
    }
  };

  const loadFulfilmentSummary = useCallback(async () => {
    let url = `${endpoints.fulfilments.mainUrl}/summary`;

    try {
      const response = await axios.get(url);

      setFulfilmentSummary(response.data);
    } catch (error) {
      addToast('Network error occured', 'error');
      console.error(error);
    }
  }, []);

  const searchRemoteData = async (event: any) => {
    let searchParamInput = document.getElementById('advSearchParam') as HTMLInputElement;
    const advSearchParam = searchParamInput.value;

    if (advSearchParam === '') {
      return;
    }
    event.preventDefault();

    //call remote api
    //set table data to result. allData still holds initial result? (or not)
    const url = `${endpoints.fulfilments.mainUrl}/search?searchparam=${advSearchParam}`;
    setIsSearch(true);
    try {
      const response = await axios.get(url);
      setPrescriptionData(response.data);
    } catch (error) {
      addToast('An error occured');
    } finally {
      setIsSearch(false);
    }
  };
  const exportTable = () => {
    exportToCSV('fulfilment-table', 'fulfilments');
  };

  const filterPendingFulfilments = (event: any) => {
    event.preventDefault();
    setFulfilmentStatus('Pending');
  };

  // const goToUpload = () => {
  //   history.push('/fulfilments/upload');
  // };

  const hideModal = (event: any) => {
    setShowSideModal(false);
    setShowStatusModal(false);
  };

  const openModal = (data: IFulfilmentResponse, modalType: 'Tracker' | 'Info') => {
    setSelectedData(data);
    if (modalType === 'Info') {
      setShowStatusModal(false);
      setShowSideModal(true);
    } else {
      setShowSideModal(false);
      setShowStatusModal(true);
    }
  };

  const closeModal = (modalType: 'Tracker' | 'Info') => {
    if (modalType === 'Info') {
      setShowSideModal(false);
    } else {
      setShowStatusModal(false);
    }
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, hideModal);

  useEffect(() => {
    //check if pharmacylist in session
    const savedPharmacyList = sessionStorage.getItem('pharmacy-list');

    if (!savedPharmacyList || savedPharmacyList === null) {
      //get pharmacy list via api
      getPharmacies();
    }
  }, []);

  useEffect(() => {
    loadFulfilments();
    loadFulfilmentSummary();
  }, [loadFulfilments, loadFulfilmentSummary]);

  let sideModal = (
    <FulfilmentSideModal showModal={showSideModal} modalData={selectedData} close={closeModal} />
  );
  let statusSideModal = (
    <RequestStatusTrackingSidebar
      showModal={showStatusModal}
      modalData={selectedData}
      close={closeModal}
    />
  );

  return (
    <div style={{ backgroundColor: '#F5F5F5' }}>
      <div ref={wrapperRef}>{sideModal}</div>
      <div ref={wrapperRef}>{statusSideModal}</div>
      {/* <h5 className='text-center' style={{ paddingTop: '20px', fontWeight: 500 }}>
        Fulfilments
      </h5> */}
      <div>
        <h5 className='fw-light'>{timeOfDayGreeting('')}</h5>
        <div className='ml-4'>
          {new Date().toLocaleDateString('en-GB', {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
          })}
        </div>
      </div>
      <br />
      <div className='row'>
        <div className='col-lg-4 col-md-6'>
          <Link
            to='/fulfilments/new'
            style={{ textDecoration: 'none', color: '#fff' }}
            className='card p-3 card-shadow bg-green green-background'
          >
            <h5 className='fw-light'>Total fulfilments requests</h5>
            <h4 className='fw-bold'>{fulfilmentSummary?.totalFulfilments}</h4>
            <div className='mt-4'>
              <span className='bi bi-plus-circle mr-2 text-blue'></span>
              <span style={{ textDecoration: 'none', color: '#fff' }}>Create a new fulfilment</span>
            </div>
          </Link>
        </div>
        <div
          className='col-lg-4 col-md-6'
          onClick={filterPendingFulfilments}
          title='Show all pending request'
        >
          <div
            className='card p-3 card-shadow bg-blue blue-background'
            style={{ textDecoration: 'none', color: '#fff' }}
          >
            <h5 className='fw-light'>Total pending requests</h5>
            <h4 className='fw-bold'>{fulfilmentSummary?.totalPending}</h4>
            <div className='mt-4'>
              {/* <span className='fs-7'>Today: {fulfilmentSummary?.totalToday}</span> */}
              <span className='bi bi-eye-fill mr-2'></span>
              <span className=''>View pending fulfilments</span>
            </div>
          </div>
        </div>
      </div>
      <div className='row mt-5 p-4' style={{ backgroundColor: '#fff', borderRadius: '10px' }}>
        <div className='col-sm-6 mt-2'>
          <div className=' form-inline'>
            <input
              type='search'
              className='form-control'
              style={{ width: '80%' }}
              placeholder='Search by name and phone, enrolle Id'
              title='Search by name and phone, enrolle Id'
              onChange={searchTable}
              id='advSearchParam'
            />
            {advSearch ? (
              <button className='btn btn-success' onClick={searchRemoteData}>
                {isSearch ? <ButtonLoader /> : 'Search'}
              </button>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className='col-sm-6 text-right mt-2'>
          {/* <button className='btn btn-primary'>
            <Link to='/fulfilments/new' style={{ textDecoration: 'none', color: 'black' }}>
              Create New +
            </Link>
          </button> */}

          <button className='btn btn-success ml-3' title='Download csv' onClick={exportTable}>
            Export <span className='bi bi-download'></span>
          </button>
          {/* <button className='btn btn-success ml-3' title='Upload csv' onClick={goToUpload}>
            Upload<span className='ml-2 bi bi-upload'></span>
          </button> */}
        </div>
      </div>
      <br />
      <div className='table-responsive mt-4'>
        {fulfilmentStatus ? (
          <div>
            <h6>
              Showing all {fulfilmentStatus} fulfilments
              <span
                className='pointer fs-8 text-danger ml-3 pointer'
                onClick={() => setFulfilmentStatus(undefined)}
              >
                Clear selection
              </span>
            </h6>
          </div>
        ) : (
          ''
        )}
        <table id='fulfilment-table' className='table table-hover table-borderless custom-table'>
          <thead>
            <tr>
              <th>Action</th>
              <th>Date</th>
              <th>Status</th>
              <th>HMO ID</th>
              <th>Patient Name</th>
              <th>Patient Phone</th>
              {/* <th>Email</th> */}
              <th>Address</th>
              {/* <th>Pharmacy</th>
                <th>Service Type</th>
                <th>Drugs</th> */}
            </tr>
          </thead>
          <tbody>
            {prescriptionData.map((data, key) => (
              <tr key={key} className='custom-tr'>
                <td>
                  <button
                    className='btn btn-sm btn-success btn-block'
                    style={{ width: '70px' }}
                    onClick={() => openModal(data, 'Info')}
                  >
                    View <span className='bi bi-eye'></span>
                  </button>
                  {/* <button className='btn'>
                    <span className='bi bi-bicycle'></span>
                  </button> */}
                </td>
                <td>{new Date(data.dateCreated).toLocaleString()}</td>
                <td>
                  <button
                    className='btn btn-sm btn-outline-info'
                    title='See Tracking information'
                    onClick={() => openModal(data, 'Tracker')}
                  >
                    {formatFulfilmentStatus(data.fulfilmentStatus)}
                    {/* <span className='ml-1 bi bi-bicycle'></span> */}
                  </button>
                </td>

                <td>{data.enrollmentCode}</td>
                <td>
                  {data.enrolleeFirstName} {data.enrolleeLastName}
                </td>
                <td>{data.enrolleePhone}</td>
                {/* <td>{data.enrolleeEmail}</td> */}
                <td>{data.enrolleeAddress}</td>
                {/* <td>
                    {formatFulfilmentStatus(data.fulfilmentStatus) ===
                    "Dispensed"
                      ? data.pharmacyName
                      : "Pending"}
                  </td> */}
                {/* <td>{data.fulfilmentService}</td>
                  <td>{data.drugs}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <br />
      {showPagination ? (
        <PaginationBar
          currentPageNumber={pageInfo.pageIndex}
          numberOfPages={pageInfo.pageCount}
          callback={loadFulfilments}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default FulfilmentsView;
