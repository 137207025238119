import { Dispatch, AppState } from '../interfaces/AppInterfaces';

const appReducer = (state: AppState, action: Dispatch): AppState => {
  switch (action.type) {
    case 'LOG_OUT':
      sessionStorage.clear();
      localStorage.clear();
      localStorage.setItem('rememberMe', 'false');
      return {
        partnerName: '',
        logoUrl: '',
      };

    case 'SET_PROFILE':
      return action.payload;

    case 'SET_ENROLLEE':
      return {
        ...state,
        selectedEnrollee: action.payload,
      };
    case 'UPDATE_PROP': //updates a property of the AppState object e.g. partnerName. to use, pass the property name and new value e.g 'partnerNam', 'Adam
      return { ...state, [action.payload.propName]: action.payload.propData };

    default:
      return state;
  }
};

export default appReducer;
