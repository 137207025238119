import Header from './Header';
import Sidebar from './Sidebar';
import { Redirect, Route } from 'react-router-dom';
import React, { useState } from 'react';
import { IPrivateRouteProps } from '../../interfaces/AppInterfaces';
import { useAuth } from 'react-oidc-context';
import axios from 'axios';
import ConfirmationModal from '../common/ConfirmationModal';

const Layout = ({ component: Component, ...rest }: IPrivateRouteProps) => {
  const auth = useAuth();
  const [showModal, setShowModal] = useState(false);

  const loginAuth = () => {
    //save the current route so we can navigate to it after login
    sessionStorage.setItem('return-url', window.location.href);
    auth
      .signinRedirect()
      .then((response) => {})
      .catch((error) => {
        window.location.href = '/';
      });
  };

  //if an error occurs during renewal,
  if (auth.error) {
    if (showModal) {
      console.error('');
    } else {
      setShowModal(true);
    }
  }

  if (!auth.isLoading) {
    //if authenticated, set axios header
    if (auth.isAuthenticated) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${auth.user?.access_token}`;
    } else {
      loginAuth();
      //auth.signinRedirect();
    }
  }

  return (
    <div>
      {showModal ? (
        <ConfirmationModal
          show={showModal}
          hide={() => setShowModal(false)}
          title='Session expired'
          okCallback={loginAuth}
          content='Click Ok to Login'
        />
      ) : (
        <div></div>
      )}
      <div>
        {auth.isLoading ? (
          <div>Loading</div>
        ) : auth.isAuthenticated ? (
          <div>
            <Header />
            <div className='container-fluid' style={{ backgroundColor: '#F5F5F5' }}>
              <span
                id='request-spinner'
                className='spinner-border spinner-border-sm text-success request-spinner d-none'
                role='status'
                aria-hidden='true'
              ></span>

              <div className='row'>
                <Sidebar />
                <main
                  role='main'
                  className='col-md-9 ml-sm-auto col-lg-10 px-md-4'
                  style={{ marginTop: '30px', marginBottom: '100px' }}
                >
                  <Route
                    {...rest}
                    render={
                      (props) => (
                        // auth.isLoading ? (
                        //   <div>Loading</div>
                        // ) : auth.isAuthenticated ? (
                        <Component {...props} />
                      )
                      // ) : (
                      //   // <div>Not Logged in</div>
                      //   <Redirect to="/" />
                      // )
                    }
                  />
                  {/* <Route
              {...rest}
              render={({ history }) => <Component history={history} />}
            /> */}
                </main>
              </div>
            </div>
          </div>
        ) : (
          <Redirect to='/' />
        )}
      </div>
    </div>
  );
};

export default Layout;
