import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IRefillBulkEditDTO } from '../../interfaces/RefillInterface';
import { endpoints } from '../../utils/URLs';
import { addToast } from '../../utils/toastNotifications';
import LoaderWithText from '../common/LoaderWithText';
import { isFormValidated } from '../../utils/formUtils';
import ButtonLoader from '../common/ButtonLoader';
const RefillBulkSideModal = ({
  show,
  handleClose,
  groupId,
}: {
  show: boolean;
  handleClose: any;
  groupId: number | undefined;
}) => {
  const [refillData, setRefillData] = useState<IRefillBulkEditDTO | undefined>();
  const [isLoading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const handleChange = (input: string) => (event: any) => {
    setRefillData({
      ...refillData!,
      [input]: event.target.value,
    });
    setIsEdited(true);
  };

  const updateRefill = async (event: any) => {
    if (!isFormValidated('refill-edits')) {
      return;
    }
    event.preventDefault();

    setLoading(true);
    const url = `${endpoints.refills.mainUrl}/bulk/${groupId}/details`;
    try {
      await axios.put(url, refillData);
      addToast('Refill updated successfully', 'success');
      handleClose();
    } catch (error) {
      addToast('An error occured while updating request', 'error');
    } finally {
      setLoading(false);
    }
  };

  const loadRefill = useCallback(async () => {
    //load exisiting refill drug details
    if (!groupId) {
      addToast('Please select a refill', 'error');
      return;
    }
    const url = `${endpoints.refills.mainUrl}/bulk/${groupId}/details`;
    setRefillData(undefined);
    setIsEdited(false);
    try {
      const response = await axios.get(url);
      const data = response.data as IRefillBulkEditDTO;
      setRefillData(data);
    } catch (error) {
      addToast('Could not load refill info', 'error');
    } finally {
    }
  }, [groupId]);

  useEffect(() => {
    // loadDrugs();
    if (show) {
      loadRefill();
    }
  }, [show, loadRefill]);

  return (
    <div
      id='sidebarMenu'
      className={
        show
          ? 'col-md-6 col-lg-4 right-sidebar sideshow'
          : 'col-md-6 col-lg-4 right-sidebar sidehide'
      }
    >
      <div className='right-sidebar-sticky pt-3 mt-2 bg-green-light'>
        <div>
          <span
            className='btn btn-sm fs-8'
            style={{ marginLeft: '-10px' }}
            onClick={() => handleClose()}
          >
            Close (x)
          </span>
        </div>
        <label style={{ color: '#1489C8' }}>Edit Refill Information</label>
        <div className='text-danger'>Changes to this section affects all refills in this group</div>
        {refillData ? (
          <form id='refill-edits'>
            <fieldset disabled={isLoading}>
              <Modal.Body>
                <div className='form-group mt-4'>
                  <label>Refill Date </label>
                  <input
                    type='date'
                    className='form-control'
                    placeholder=''
                    value={refillData!.refillDate.split('T')[0]}
                    onChange={handleChange('refillDate')}
                  />
                </div>
                <div className='form-group mt-4'>
                  <label>PA Code </label>
                  <input
                    type='text'
                    className='form-control'
                    placeholder=''
                    value={refillData.preAuthorizationCode || ''}
                    onChange={handleChange('preAuthorizationCode')}
                  />
                </div>
                <hr />
                <div className='form-group mt-3'>
                  <label>Enrollee Address</label>
                  <textarea
                    className='form-control'
                    placeholder=''
                    value={refillData!.enrolleeAddress}
                    onChange={handleChange('enrolleeAddress')}
                    rows={5}
                  />
                </div>
              </Modal.Body>

              <Modal.Footer>
                <button
                  className='btn btn-primary'
                  type='submit'
                  onClick={updateRefill}
                  style={{ backgroundColor: '#1489C8' }}
                  disabled={!isEdited}
                >
                  Update Refill
                  {isLoading ? <ButtonLoader /> : ''}
                </button>
              </Modal.Footer>
            </fieldset>
          </form>
        ) : (
          <LoaderWithText />
        )}
      </div>
    </div>
  );
};

export default RefillBulkSideModal;
