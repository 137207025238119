export function isFormValidated(formName: string) {
  let form = document.getElementById(formName) as any;
  let isValid = form !== null ? form.checkValidity() : false;
  return isValid;
}

export const validatePhoneNumber = (phone: string) => {
  let pattern = '(^(?:[+0]9)?[0-9]{11,13}$)';
  let message = '';
  let regExp = new RegExp(pattern);
  if (phone.length < 11 || phone.length > 13) {
    message = 'Phone number must be between 11 and 13';
    return message;
  } else if (!regExp.test(phone)) {
    message = 'Please enter a phone number between 11 and 13 digits';
    return message;
  }
};

export const formatPhoneNumber = (phoneNumber: string) => {
  let code = '234';
  let phone = phoneNumber;

  if (phone.startsWith(code) || phone.startsWith(`+${234}`)) {
    return phone;
  }

  if (phone.startsWith('0')) {
    return `${code}${phone.substr(1)}`;
  } else {
    return `${code}${phone}`;
  }
};
