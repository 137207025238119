import { useEffect, useState } from 'react';
import { endpoints } from '../../utils/URLs';
import axios from 'axios';
import { ISubscriptionDTO } from '../../interfaces/Subscriptions';
import TableLoader from '../common/TableLoader';
import PaginationBar from '../common/Pagination';

const Subscriptions = () => {
  const [pageInfo, setPageInfo] = useState({
    pageIndex: 1,
    pageCount: 1,
  });

  const [subscriptions, setSubscriptions] = useState<ISubscriptionDTO[] | undefined>();

  const loadSubscriptions = async (pageIndex: number = 1, pageSize: number = 50) => {
    const url = `${endpoints.subscriptions.mainUrl}?pageIndex=${pageIndex}&pageSize=${pageSize}`;

    try {
      const response = await axios.get(url);
      const data = response.data.data;
      setSubscriptions(data);
      let newPageInfo = {
        pageIndex: response.data.pageIndex,
        pageCount: response.data.pageCount,
      };
      setPageInfo(newPageInfo);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadSubscriptions();
  }, []);

  return (
    <div>
      <div className='table-responsive'>
        <h5 className='text-center' style={{ marginTop: '20px', fontWeight: 500 }}>
          Subscriptions
        </h5>

        <br />
        <div className='table-responsive'>
          <table className='table table-hover table-borderless custom-table'>
            <thead>
              <tr>
                <th>Subscription Code</th>
                <th>Name</th>
                <th>Status</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Product</th>
                <th>People in plan</th>
                <th>Amount</th>
                <th>Expiry date</th>
              </tr>
            </thead>
            {subscriptions ? (
              <tbody>
                {subscriptions.length === 0 ? (
                  <tr className='text-center'>
                    <td colSpan={100}> No data found </td>
                  </tr>
                ) : (
                  subscriptions.map((data) => (
                    <tr key={data.subscriptionCode} className='custom-tr'>
                      <td>{data.subscriptionCode}</td>
                      <td>
                        {data.firstName} {data.lastName}
                      </td>
                      <td>{data.status}</td>
                      <td>{data.phone}</td>
                      <td>{data.email}</td>
                      <td>{data.product}</td>
                      <td>{data.numberOfSubscribers}</td>
                      <td>{data.amount}</td>
                      <td>{new Date(data.nextPayment).toLocaleString()}</td>
                    </tr>
                  ))
                )}
              </tbody>
            ) : (
              <TableLoader />
            )}
          </table>
        </div>
      </div>
      <br />
      <PaginationBar
        currentPageNumber={pageInfo.pageIndex}
        numberOfPages={pageInfo.pageCount}
        callback={loadSubscriptions}
      />
    </div>
  );
};

export default Subscriptions;
