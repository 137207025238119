const RefreshToast = () => {
  //a hack to unregister the current service worker and force a reload
  //https://stackoverflow.com/a/46982400/2929906
  const reloadPage = (event: any) => {
    if ('serviceWorker' in window.navigator) {
      window.navigator.serviceWorker
        .getRegistration()
        .then((registration) => {
          if (registration) {
            registration?.unregister().then(() => {
              console.log('service worker unregistered. reloading page...');
              window.sessionStorage.clear();
              window.localStorage.clear();
              window.location.reload();
            });
          } else {
            console.log('unable to unregister service worker. reloading page...');

            window.sessionStorage.clear();
            window.localStorage.clear();
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log('unable to unregister service worker due to error. reloading page...');

          console.error(error);
        });
    } else {
      window.sessionStorage.clear();
      window.localStorage.clear();
      window.location.reload();
      console.error('No service worker in navigator');
    }
  };

  return (
    <div
      id='refresh-btn'
      className='refresh-toast text-center'
      onClick={reloadPage}
      style={{ display: 'none' }}
    >
      New version available!! Click to update
    </div>
  );
};

export default RefreshToast;
