import React, { useContext, useEffect, useState } from 'react';
import { IEnrolleeSummary } from '../../interfaces/UtilizationInterfaces';
import { AppContext } from '../../context/AppContext';
import { AppState } from '../../interfaces/AppInterfaces';
import DataCard from './DataOverview/DataCard';
import { formatDate, validateDateRange } from '../../utils/dateTimeUtils';
import EnrolleeFulfilments from './Fulfilments';
import ButtonLoader from '../common/ButtonLoader';
import axios from 'axios';
import { endpoints } from '../../utils/URLs';
import { addToast } from '../../utils/toastNotifications';

const EnrolleeInfo = () => {
  const [enrollee, setEnrollee] = useState<IEnrolleeSummary | undefined>();
  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
  const [isFiltering, setIsFiltering] = useState(false);
  const { appState }: { appState: AppState } = useContext(AppContext);

  const handleDateChange = (input: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setDateRange({
      ...dateRange,
      [input]: event.target.value,
    });
  };

  const filterUtilizationData = async (
    searchQuery: string,
    startDate: string,
    endDate: string,
    setIsFiltering: (value: boolean) => void
  ) => {
    const validationResult = validateDateRange(startDate, endDate);

    if (!validationResult.isValid) {
      addToast(validationResult.msg, 'error');
      return;
    }

    setIsFiltering(true);
    try {
      const url = `${endpoints.utilizationReport.mainUrl}/search`;
      const params = {
        searchQuery,
        startDate,
        endDate,
      };
      const response = await axios.get(url, { params });
      return response.data;
    } catch (error) {
      addToast('An error occurred while filtering data', 'error');
      return null;
    } finally {
      setIsFiltering(false);
    }
  };

  const filterData = async () => {
    if (!enrollee) {
      return;
    }
    setIsFiltering(true);
    try {
      const result = await filterUtilizationData(
        enrollee.enrolleeId,
        dateRange.startDate,
        dateRange.endDate,
        setIsFiltering
      );
      if (result && result.length > 0) {
        setEnrollee(result[0]);
      } else {
        setEnrollee({
          ...enrollee,
          totalCost: 0,
          totalFulfilments: 0,
        });
      }
    } finally {
      setIsFiltering(false);
    }
  };

  useEffect(() => {
    if (appState && appState.selectedEnrollee) {
      setEnrollee(appState.selectedEnrollee);
    }
  }, [appState]);

  return (
    <div className='container'>
      <div className='card mt-3 mb-4'>
        <div className='card-body py-2'>
          <div className='row align-items-center'>
            <div className='col-md-3 col-sm-6 mb-2 mb-md-0'>
              <input
                type='date'
                className='form-control form-control-sm'
                value={dateRange.startDate}
                onChange={handleDateChange('startDate')}
                placeholder='Start Date'
              />
            </div>
            <div className='col-md-3 col-sm-6 mb-2 mb-md-0'>
              <input
                type='date'
                className='form-control form-control-sm'
                value={dateRange.endDate}
                onChange={handleDateChange('endDate')}
                placeholder='End Date'
              />
            </div>
            <div className='col-md-2 col-sm-12'>
              <button
                className='btn btn-primary btn-sm w-100'
                onClick={filterData}
                disabled={isFiltering}
              >
                Filter {isFiltering && <ButtonLoader />}
              </button>
            </div>
          </div>
        </div>
      </div>

      {enrollee && (
        <div className='bg-light p-4 rounded shadow mb-4 row'>
          <div className='col-md-6'>
            <div className='bg-white shadow rounded card pt-4 pb-5 px-4'>
              <div>
                Enrollee Name:<strong className='ml-3'>{enrollee.enrolleeName}</strong>
              </div>
              <div className='mt-2'>
                Start Date:<strong className='ml-3'>{formatDate(enrollee.dateCreated)}</strong>
              </div>
            </div>
          </div>
          <DataCard
            title='Total Spend'
            value={enrollee.totalCost}
            iconClass='bi bi-currency-dollar'
            iconBgClass='bg-primary'
            isCurrency={true}
          />
          <DataCard
            title='Average Cost'
            value={
              enrollee.totalFulfilments > 0
                ? parseFloat((enrollee.totalCost / enrollee.totalFulfilments).toFixed(2))
                : 0
            }
            iconClass='bi bi-bar-chart'
            iconBgClass='bg-success'
            isCurrency={true}
          />
        </div>
      )}

      <EnrolleeFulfilments
        hmoId={appState.selectedEnrollee?.enrolleeId || ''}
        startDate={dateRange.startDate}
        endDate={dateRange.endDate}
      />
    </div>
  );
};

export default EnrolleeInfo;
