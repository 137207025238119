const NotFound = ({ notFoundText }: { notFoundText: string }) => {
  return (
    <div className='text-center'>
      <span
        style={{ fontSize: '5rem' }}
        className='bi-exclamation-triangle-fill exclamation-icon text-danger'
      ></span>
      <div className='exclamation-desc'>{notFoundText}</div>
    </div>
  );
};

export default NotFound;
