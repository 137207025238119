import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastProvider, DefaultToastContainer } from 'react-toast-notifications';
import { AppProvider } from './context/AppContext';
import 'bootstrap/dist/css/bootstrap.min.css';
// components
import LoginForm from './components/auth/Login';
import Layout from './components/dashboard/Layout';
import FulfilmentsView from './components/fulfilments/FulfilmentsView';
import NewFulfilment from './components/fulfilments/NewFulfilment';
import Claims from './components/claims/Claims';
import Subscriptions from './components/subscriptions/Subscriptions';

import RefreshToast from './components/common/RefreshToast';
// stylesheet
import './components/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Callback from './components/auth/Callback';
import Settings from './components/settings/Settings';
import RefillsTable from './components/refills/RefillsTable';

import { AuthProvider } from 'react-oidc-context';
import BulkFulfilmentUpload from './components/fulfilments/BulkFulfilmentUpload';
import FulfilmentTracking from './components/tracking/FulfilmentTracking';
import ClaimUtilization from './components/utilization/ClaimUtilization';
import EnrolleeInfo from './components/utilization/EnrolleeInfo';

const oidcConfig = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY,
  client_id: process.env.REACT_APP_OIDC_CLIENTID,
  redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URL,
  response_type: 'code',
  scope: 'openid profile email roles',
  post_logout_redirect_uri: process.env.REACT_APP_OIDC_POSTLOGOUT_URL,
  filterProtocolClaims: true,
  //userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: true,
  silent_redirect_uri: process.env.REACT_APP_OIDC_RENEWAL_URL,
  revokeAccessTokenOnSignout: true,
};
// //adds the context to window so we can access anywhere
declare global {
  interface Window {
    __react_toast_provider: any;
  }
}

window.__react_toast_provider = React.createRef();
// create a default container so we can override the styles
const ToastContainer = (props: any) => (
  <DefaultToastContainer style={{ zIndex: '1900' }} {...props} />
);

const App = (): JSX.Element => {
  return (
    <AuthProvider {...oidcConfig}>
      <ToastProvider components={{ ToastContainer }} ref={window.__react_toast_provider}>
        <AppProvider>
          <BrowserRouter>
            <Switch>
              <Route exact path='/signin-callback' component={Callback} />
              <Route component={LoginForm} exact path='/login' />
              <Route component={LoginForm} exact path='/' />
              <Layout exact path='/settings' component={Settings} />
              <Layout exact path='/fulfilments' component={FulfilmentsView} />
              <Layout exact path='/fulfilments/new' component={NewFulfilment} />
              <Layout exact path='/fulfilments/upload' component={BulkFulfilmentUpload} />
              <Route exact path='/fulfilments/tracking' component={FulfilmentTracking} />
              <Layout exact path='/claims' component={Claims} />
              <Layout exact path='/utilization' component={ClaimUtilization} />
              <Layout exact path='/utilization/view' component={EnrolleeInfo} />
              <Layout exact path='/subscriptions' component={Subscriptions} />
              <Layout exact path='/refills' component={RefillsTable} />
            </Switch>
            <RefreshToast />
          </BrowserRouter>
        </AppProvider>
      </ToastProvider>
    </AuthProvider>
  );
};

export default App;
