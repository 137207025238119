import { useCallback, useEffect, useRef, useState } from 'react';
import { IFulfilmentResponse } from '../../interfaces/FulfilmentInterfaces';
import { endpoints } from '../../utils/URLs';
import axios from 'axios';
import { addToast } from '../../utils/toastNotifications';
import FulfilmentSideModal from '../fulfilments/FulfilmentSideModal';
import RequestStatusTrackingSidebar from '../fulfilments/RequestStatusTrackingSidebar';
import { useOutsideAlerter } from '../common/OutsideClick';

const EnrolleeFulfilments = ({
  hmoId,
  startDate,
  endDate,
}: {
  hmoId: string;
  startDate: string;
  endDate: string;
}) => {
  const [data, setData] = useState<IFulfilmentResponse[]>([]);
  const [showSideModal, setShowSideModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedData, setSelectedData] = useState<IFulfilmentResponse | undefined>();

  const formatFulfilmentStatus = (fulfilmentStatus: string) => {
    switch (fulfilmentStatus) {
      case 'Dispensed':
      case 'Approved':
      case 'Completed':
        return 'Dispensed';
      default:
        return fulfilmentStatus;
    }
  };

  const loadFulfilments = useCallback(async () => {
    let url = `${endpoints.fulfilments.mainUrl}/hmo?hmoId=${hmoId}&`;

    if (startDate && startDate !== '') {
      url += `startDate=${startDate}&`;
    }
    if (endDate && endDate !== '') {
      url += `endDate=${endDate}&`;
    }

    try {
      const response = await axios.get(url);

      setData(response.data);
    } catch (error) {
      addToast('Network error occured', 'error');
      console.error(error);
    }
  }, [hmoId, startDate, endDate]);

  const openModal = (data: IFulfilmentResponse, modalType: 'Tracker' | 'Info') => {
    setSelectedData(data);
    if (modalType === 'Info') {
      setShowStatusModal(false);
      setShowSideModal(true);
    } else {
      setShowSideModal(false);
      setShowStatusModal(true);
    }
  };
  const closeModal = (modalType: 'Tracker' | 'Info') => {
    if (modalType === 'Info') {
      setShowSideModal(false);
    } else {
      setShowStatusModal(false);
    }
  };

  const hideModal = (event: any) => {
    setShowSideModal(false);
    setShowStatusModal(false);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, hideModal);

  let sideModal = (
    <FulfilmentSideModal showModal={showSideModal} modalData={selectedData} close={closeModal} />
  );
  let statusSideModal = (
    <RequestStatusTrackingSidebar
      showModal={showStatusModal}
      modalData={selectedData}
      close={closeModal}
    />
  );

  useEffect(() => {
    loadFulfilments();
  }, [loadFulfilments]);

  return (
    <div className='mt-5'>
      <div ref={wrapperRef}>{sideModal}</div>
      <div ref={wrapperRef}>{statusSideModal}</div>
      <table id='fulfilment-table' className='table table-hover table-borderless custom-table'>
        <thead>
          <tr>
            <th>Action</th>
            <th>Date</th>
            <th>Status</th>
            <th>HMO ID</th>
            <th>Patient Name</th>
            <th>Patient Phone</th>
            {/* <th>Email</th> */}
            <th>Address</th>
            {/* <th>Pharmacy</th>
                <th>Service Type</th>
                <th>Drugs</th> */}
          </tr>
        </thead>
        <tbody>
          {data.map((data, key) => (
            <tr key={key} className='custom-tr'>
              <td>
                <button
                  className='btn btn-sm btn-success btn-block'
                  style={{ width: '70px' }}
                  onClick={() => openModal(data, 'Info')}
                >
                  View <span className='bi bi-eye'></span>
                </button>
                {/* <button className='btn'>
                    <span className='bi bi-bicycle'></span>
                  </button> */}
              </td>
              <td>{new Date(data.dateCreated).toLocaleString()}</td>
              <td>
                <button
                  className='btn btn-sm btn-outline-info'
                  title='See Tracking information'
                  onClick={() => openModal(data, 'Tracker')}
                >
                  {formatFulfilmentStatus(data.fulfilmentStatus)}
                  {/* <span className='ml-1 bi bi-bicycle'></span> */}
                </button>
              </td>

              <td>{data.enrollmentCode}</td>
              <td>
                {data.enrolleeFirstName} {data.enrolleeLastName}
              </td>
              <td>{data.enrolleePhone}</td>
              {/* <td>{data.enrolleeEmail}</td> */}
              <td>{data.enrolleeAddress}</td>
              {/* <td>
                    {formatFulfilmentStatus(data.fulfilmentStatus) ===
                    "Dispensed"
                      ? data.pharmacyName
                      : "Pending"}
                  </td> */}
              {/* <td>{data.fulfilmentService}</td>
                  <td>{data.drugs}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EnrolleeFulfilments;
