import { IHmoSummary } from '../../../interfaces/UtilizationInterfaces';
import { formatDateTime } from '../../../utils/dateTimeUtils';
import DataCard from './DataCard';

const DataOverview = ({ summary }: { summary: IHmoSummary }) => {
  return (
    <div className='bg-light p-4 rounded shadow mb-4 row'>
      <DataCard
        title='Total Spend'
        value={summary.totalCost}
        iconClass='bi bi-currency-dollar'
        iconBgClass='bg-primary'
        isCurrency={true}
      />
      <DataCard
        title='Average medication Cost'
        value={summary.averageCost}
        iconClass='bi bi-bar-chart'
        iconBgClass='bg-success'
        isCurrency={true}
      />
      <DataCard
        title='Average spend per Enrollee'
        value={summary.avgCostPerMonth}
        iconClass='bi bi-person-check'
        iconBgClass='bg-info'
        isCurrency={true}
      />
      <DataCard
        title='Total Enrollees'
        value={summary.totalEnrollees}
        iconClass='bi bi-people'
        iconBgClass='bg-warning'
        isCurrency={false}
      />
      <DataCard
        title='Total Fulfilments'
        value={summary.totalFulfilments}
        iconClass='bi bi-box-seam'
        iconBgClass='bg-danger'
        isCurrency={false}
      />
      <div className='col-12 text-right fs-10'>
        Data fetched: {formatDateTime(summary.dateFetched)}
      </div>
    </div>
  );
};

export default DataOverview;