import React, { useReducer, createContext, useEffect } from 'react';
import { AppState } from '../interfaces/AppInterfaces';
import appReducer from './AppReducer';

const initialAppState: AppState = {
  partnerName: '',
  logoUrl: '',
  selectedEnrollee: undefined,
};

export const AppContext = createContext<AppState | any>(initialAppState);

const loadState = () => {
  try {
    // const token = sessionStorage.getItem("token") as string;
    // setAuthToken(token);
    const serializedState = sessionStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

// get state from session storage
const storedState = loadState();

// provider function
//export const AppProvider = (props: ContextProps): JSX.Element => {
export const AppProvider = (props: any): JSX.Element => {
  const [appState, dispatch] = useReducer(appReducer, storedState || initialAppState);
  const { children } = props;

  // save state to session everytime the values in state changes.
  useEffect(() => {
    sessionStorage.setItem('state', JSON.stringify(appState));
  }, [appState]);

  return <AppContext.Provider value={{ appState, dispatch }}>{children}</AppContext.Provider>;
};
