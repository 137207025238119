import { Link } from 'react-router-dom';

const HeaderLogo = () => {
  return (
    <div className='home-header-area'>
      <div className='container-fluid'>
        <div className='row justify-content-between'>
          <div className='col-3'>
            <div className='logo'>
              <Link to='/'>
                <img
                  src={require('../../assets/images/wellahealth.svg').default}
                  className='logo-img'
                  alt='WellaHealth'
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderLogo;
