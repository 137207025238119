import axios from 'axios';
import { useEffect, useState } from 'react';
import { initialProfile, IProfileSettings } from '../../interfaces/SettingsInterface';
import { isFormValidated } from '../../utils/formUtils';
import { SettingsTabs } from '../../utils/mappings';
import { addToast } from '../../utils/toastNotifications';
import { endpoints } from '../../utils/URLs';
import ButtonLoader from '../common/ButtonLoader';
import LoaderWithText from '../common/LoaderWithText';

const Profile = ({ tabKey }: { tabKey: string }) => {
  const [profile, setProfile] = useState<IProfileSettings | undefined>();
  const [btnLoading, setBtnLoading] = useState(false);

  const getProfile = async () => {
    const url = endpoints.profile.mainUrl;

    try {
      const response = await axios.get(url);
      const data = response.data;
      setProfile(data);
    } catch (error) {
      console.error(error);
      setProfile(initialProfile);
    }
  };

  const handleChange = (input: string) => (event: any) => {
    setProfile({
      ...profile!,
      [input]: event.target.value,
    });
  };

  const save = async (event: any) => {
    if (!isFormValidated('profile-form')) {
      return;
    }
    event.preventDefault();
    setBtnLoading(true);
    const url = endpoints.profile.mainUrl;
    try {
      await axios.put(url, profile);
      addToast('Profile updated', 'success');
    } catch (error) {
      addToast('Error occured when updating profile', 'error');
    } finally {
      setBtnLoading(false);
    }
  };

  useEffect(() => {
    if (tabKey !== SettingsTabs.Profile) {
      return;
    }
    getProfile();
  }, [tabKey]);

  return (
    <div>
      <h5 className='mt-5 text-center'>Profile</h5>

      {profile ? (
        <form id='profile-form'>
          <fieldset disabled={btnLoading}>
            <div className='form-group row mt-4'>
              <label className='col-sm-3 col-form-label'>Company Name</label>
              <div className='col-sm-9'>
                <input className='form-control' value={profile?.partnerName} readOnly={true} />
              </div>
            </div>
            <div className='form-group row mt-4'>
              <label className='col-sm-3 col-form-label'>Contact Phone</label>
              <div className='col-sm-9'>
                <input
                  type='phone'
                  maxLength={13}
                  className='form-control'
                  value={profile?.phoneNumber}
                  onChange={handleChange('phone')}
                  pattern='[0]{1}[7-9]{1}[0-2]{1}[0-9]{8}|[2]{1}[3]{1}[4]{1}[0-9]{10}'
                  required
                />
              </div>
            </div>
            <div className='form-group row mt-4'>
              <label className='col-sm-3 col-form-label'>Contact Email</label>
              <div className='col-sm-9'>
                <input
                  type='email'
                  className='form-control'
                  value={profile?.contactEmail}
                  onChange={handleChange('contactEmail')}
                  required
                />
              </div>
            </div>
            <div className='form-group row mt-4'>
              <label className='col-sm-3 col-form-label'>Support Email</label>
              <div className='col-sm-9'>
                <input
                  type='email'
                  className='form-control'
                  required
                  value={profile?.supportEmail}
                  onChange={handleChange('supportEmail')}
                />
              </div>
            </div>
            <div className='form-group row mt-4'>
              <label className='col-sm-3 col-form-label'>Services</label>
              <div className='col-sm-9'>
                <input className='form-control' readOnly={true} value={profile?.services} />
              </div>
            </div>
            <div className='form-group row justify-content-center mt-4'>
              <div className='col-sm-6'>
                <button className='btn btn-success btn-block' onClick={save}>
                  Save Changes {btnLoading ? <ButtonLoader /> : ''}
                </button>
              </div>
            </div>
          </fieldset>
        </form>
      ) : (
        <LoaderWithText />
      )}
    </div>
  );
};

export default Profile;
