import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useState } from 'react';
import { SettingsTabs } from '../../utils/mappings';
import Profile from './Profile';
import ApiCredentials from './ApiCredentials';
import Account from './Account';

const Settings = () => {
  const [key, setKey] = useState(SettingsTabs.Account);

  return (
    <div className='col-sm-12' style={{ minHeight: '700px' }}>
      <div className='row justify-content-center'>
        <div className='col-sm-8'>
          <Tabs
            fill
            id='controlled-tab-example'
            activeKey={key}
            onSelect={(k) => setKey(k as string)}
          >
            <Tab eventKey={SettingsTabs.Account} title='Account'>
              <Account tabKey={key} />
            </Tab>
            <Tab eventKey={SettingsTabs.Profile} title='Profile'>
              <Profile tabKey={key} />
            </Tab>
            <Tab eventKey={SettingsTabs.ApiCredentials} title='API Credentials'>
              <ApiCredentials tabKey={key} />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Settings;
