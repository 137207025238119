import axios from 'axios';
import { useState, useEffect, ChangeEvent, useCallback } from 'react';
import { IRefillDTO, IRefillGroup, IRefillUpdateDTO } from '../../interfaces/RefillInterface';
import { formatDate } from '../../utils/dateTimeUtils';
import { RefillStatus, RefillView } from '../../utils/mappings';
import { addToast } from '../../utils/toastNotifications';
import { endpoints } from '../../utils/URLs';
import ButtonLoader from '../common/ButtonLoader';
import NoTableData from '../common/NoTableData';
import PaginationBar from '../common/Pagination';
import TableLoader from '../common/TableLoader';
import { Modal } from 'react-bootstrap';
import RefillEditSideModal from './RefillEditSideModal';
import RefillBulkSideModal from './RefillBulkSideModal';

const RefillsTable = () => {
  const [refills, setRefills] = useState<IRefillGroup[] | undefined>();
  const [allData, setAllData] = useState<IRefillGroup[]>([]); //to hold all table result in unfiltered format

  const [dataLoading, setDataLoading] = useState(0);
  const [refillView, setRefillView] = useState(RefillView[0].value);
  const [pageInfo, setPageInfo] = useState({
    pageIndex: 1,
    pageCount: 1,
  });

  const [isSearch, setIsSearch] = useState(false);
  const [advSearch, setAdvSearch] = useState(false);

  const [showPagination, setShowPagination] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);

  const [showBulkModal, setShowBulkModal] = useState(false);

  const [isApproveAll, setApproveAll] = useState(false);
  const [selectedRefill, setSelectedRefill] = useState<IRefillDTO>();

  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: '',
  });

  const changeRefillView = (event: any) => {
    setRefillView(event.target.value);
    getDateValues(event.target.value);
  };

  const getDateValues = async (viewType: string) => {
    if (viewType === 'All') {
      // setDateRange((x) => {
      //   return { ...x, startDate: '', endDate: '' };
      // });
      getRefills(1, 100, '', '');
      return;
    }

    if (viewType === 'Custom' && (dateRange.startDate === '' || dateRange.endDate === '')) {
      return;
    }

    //get the start date and end date.
    const dateNow = new Date();
    let startDate = dateNow.toISOString().split('T')[0];
    let endDate = dateNow.toISOString().split('T')[0];

    switch (viewType) {
      case 'DueInSevenDays':
        dateNow.setDate(dateNow.getDate() + 7);
        endDate = dateNow.toISOString().split('T')[0];

        break;
      case 'DueInOneMonth':
        dateNow.setMonth(dateNow.getMonth() + 1);
        endDate = dateNow.toISOString().split('T')[0];
        break;
      case 'Custom':
        startDate = dateRange.startDate;
        endDate = dateRange.endDate;
        break;
      default:
        break;
    }

    // //call api with values
    // setDateRange((x) => {
    //   return { ...x, startDate: startDate, endDate: endDate };
    // });
    // setDateRange({
    //   ...dateRange,
    //   startDate: startDate,
    //   endDate: endDate,
    // });

    await getRefills(1, 100, startDate, endDate);

    //return { startDate, endDate };
  };

  const handleDate = (input: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setDateRange({
      ...dateRange,
      [input]: value,
    });

    //if start date only, propmpt for end date
    if (input === 'startDate' && (dateRange.endDate === null || dateRange.endDate === '')) {
      addToast('Please select End Date');
      return;
    }

    if (input === 'endDate' && (dateRange.startDate === null || dateRange.startDate === '')) {
      addToast('Please select start Date');
      return;
    }

    const startDate = input === 'startDate' ? value : dateRange.startDate;
    const endDate = input === 'endDate' ? value : dateRange.endDate;

    const timeDiff = new Date(endDate).getTime() - new Date(startDate).getTime();

    if (timeDiff < 0) {
      addToast('Start date should be earlier than end date');
      return;
    }

    setDateRange({
      ...dateRange,
      [input]: value,
    });

    getRefills(1, 100, startDate, endDate);
  };

  const getRefills = useCallback(
    async (pageIndex: number, pageSize: number = 100, startDate: string, endDate: string) => {
      let url = `${endpoints.refills.mainUrl}?pageIndex=${pageIndex}&pageSize=${pageSize}`;

      //if not all, get dates and append

      if (startDate !== '' && endDate !== '') {
        url = `${endpoints.refills.mainUrl}?pageIndex=${pageIndex}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`;
      }

      try {
        const response = await axios.get(url);

        setRefills(response.data.data);
        setAllData(response.data.data);

        let newPageInfo = {
          pageIndex: response.data.pageIndex,
          pageCount: response.data.pageCount,
        };
        setPageInfo(newPageInfo);
        setShowPagination(true);
        // setDateRange({
        //   ...dateRange,
        //   startDate: startDate,
        //   endDate: endDate,
        // });
      } catch (error) {
        console.error('An error occured', 'error');
      }
    },
    []
  );

  const gotoPage = (pageNumber: number) => {
    getRefills(pageNumber, 100, dateRange.startDate, dateRange.endDate);
  };

  const updateTableData = (refillId: number, refillStatus: string) => {
    //get updated table
    let updatedRefill = refills?.filter((x) => x.refills.filter((y) => y.refillId === refillId))[0]
      .refills[0];

    refills?.forEach((x) => {
      const found = x.refills.filter((y) => y.refillId === refillId)[0];

      if (found) {
        updatedRefill = found;
      }
    });

    updatedRefill!.refillStatus = refillStatus;

    //replace it in table data
    const parsedOldData = updatedRefill || null;

    if (parsedOldData !== null) {
      const filteredData: any = [...refills!];
      filteredData[filteredData.indexOf(parsedOldData)] = updatedRefill;
      setRefills(filteredData);
    }
  };

  const bulkUpdateTableData = (groupId: number, refillStatus: string) => {
    //get updated table

    const updatedRefill = refills?.filter((x) => x.groupId === groupId)[0];

    updatedRefill?.refills.forEach((x) => {
      x.refillStatus = refillStatus;
    });

    //replace it in table data
    const parsedOldData = updatedRefill || null;

    if (parsedOldData !== null) {
      const filteredData: any = [...refills!];
      filteredData[filteredData.indexOf(parsedOldData)] = updatedRefill;
      setRefills(filteredData);
    }
  };

  const approveRefill = (selectedRefill: IRefillDTO, approveAll = false) => {
    setShowApprovalModal(true);
    setSelectedRefill(selectedRefill);
    setApproveAll(approveAll);
  };

  const updateCardStatus = async (refillInstantly: boolean, approveAll = false) => {
    setShowApprovalModal(false);

    await updateStatus(
      selectedRefill!.refillId,
      RefillStatus.Approved,
      selectedRefill!.groupId,
      approveAll,
      refillInstantly
    );
  };

  const updateStatus = async (
    refillId: number,
    refillStatus: string,
    groupId: number,
    approveAll = false,
    refillInstantly = false
  ) => {
    let payload: IRefillUpdateDTO = {
      updateId: refillId,
      refillStatus: refillStatus,
      refillInstantly: refillInstantly,
    };

    setDataLoading(refillId);
    let url = `${endpoints.refills.mainUrl}/${refillId}/status`;
    if (approveAll) {
      payload = {
        updateId: groupId,
        refillStatus: refillStatus,
        refillInstantly: refillInstantly,
      };
      setDataLoading(groupId);
      url = `${endpoints.refills.mainUrl}/bulk/${groupId}/status`;
    }

    try {
      await axios.put(url, payload);
      addToast('Refill status updated', 'success');
      if (approveAll) {
        bulkUpdateTableData(groupId, refillStatus);
      } else {
        updateTableData(refillId, refillStatus); //update table data
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDataLoading(0);
    }
  };

  const showStatusColor = (refillStatus: string) => {
    switch (refillStatus) {
      case RefillStatus.Approved:
        return 'border-success';
      case RefillStatus.Declined:
        return 'border-danger';
      default:
        return 'border-info';
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const closeBulkModal = () => {
    setShowBulkModal(false);
  };

  const editRefill = (selectedRefill: IRefillDTO) => {
    setShowBulkModal(false);
    setShowModal(true);
    setSelectedRefill(selectedRefill);
  };

  const editBulk = (selectedRefill: IRefillDTO) => {
    setShowBulkModal(true);
    setShowModal(false);
    setSelectedRefill(selectedRefill);
  };

  /* Search */

  const searchTable = (event: any) => {
    //search loaded data
    let searchParam = event.target.value;

    if (searchParam === '') {
      setRefills(allData);
      setAdvSearch(false);
      setShowPagination(true);
      return;
    }

    //if not found, search remote?
    setShowPagination(false);

    searchParam = searchParam.toLowerCase();
    const searchResult = allData.filter(
      (x) =>
        x.refills.filter((y) => y.patientName?.toLowerCase().includes(searchParam)) ||
        x.refills.filter((y) => y.patientPhone?.toLowerCase().includes(searchParam)) ||
        x.refills.filter((y) => y.patientEnrollmentCode?.toLowerCase().includes(searchParam))
    );

    // //set table data to search result
    setRefills(searchResult);

    if (searchParam.length >= 3) {
      setAdvSearch(true);
    } else {
      //setIsLoading(false);
    }
  };

  const searchRemoteData = async (event: any) => {
    let searchParamInput = document.getElementById('advSearchParam') as HTMLInputElement;
    const advSearchParam = searchParamInput.value;

    if (advSearchParam === '') {
      return;
    }
    event.preventDefault();

    //call remote api
    //set table data to result. allData still holds initial result? (or not)
    const url = `${endpoints.refills.mainUrl}/search?searchparam=${advSearchParam}`;
    setIsSearch(true);
    try {
      const response = await axios.get(url);
      setRefills(response.data);
    } catch (error) {
      addToast('An error occured');
    } finally {
      setIsSearch(false);
    }
  };

  useEffect(() => {
    //if (refillView === 'All') {
    getRefills(1, 100, '', '');
    //return;
    //}
    // getDateValues('All');
  }, [getRefills]);

  let approvalModal = (
    <Modal
      show={showApprovalModal}
      onHide={() => setShowApprovalModal(false)}
      centered
      backdrop='static'
    >
      <Modal.Header closeButton>
        <Modal.Title style={{ color: '#1489C8' }}>Select Refill Mode</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className='row'>
          {isApproveAll ? (
            <div className='ml-4 col-md-12'>
              Note: <strong>All medications </strong>in this refill group will be approved
            </div>
          ) : (
            ''
          )}
          <div className='col-6 p-5 text-center'>
            <div
              className='card-shadow card p-3 card-shadow-success'
              onClick={() => updateCardStatus(true, isApproveAll)}
            >
              <span className='bi bi-lightning' style={{ fontSize: '3rem' }}></span>
              <div>Refill instantly</div>
            </div>
            {isApproveAll ? (
              <div>All medications in the group will will be profiled within the hour</div>
            ) : (
              <small>The refill will be profiled within the hour</small>
            )}
          </div>
          <div className='col-6 p-5 text-center '>
            <div
              className='card-shadow card p-3 card-shadow-success'
              onClick={() => updateCardStatus(false, isApproveAll)}
            >
              <span className='bi bi-stopwatch' style={{ fontSize: '3rem' }}></span>
              <div>Refill later</div>
            </div>
            {isApproveAll ? (
              <div>Each medication medication in the group will be profiled on set Refill date</div>
            ) : (
              <small>The refill will be profiled on {formatDate(selectedRefill?.refillDate)}</small>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );

  let refillEditSideModal: JSX.Element = (
    <RefillEditSideModal show={showModal} handleClose={closeModal} refill={selectedRefill} />
  );

  let refillBulkSideModal: JSX.Element = (
    <RefillBulkSideModal
      show={showBulkModal}
      handleClose={closeBulkModal}
      groupId={selectedRefill?.groupId}
    />
  );

  return (
    <div>
      {refillEditSideModal}
      {refillBulkSideModal}
      {approvalModal}
      <h5 className='text-center' style={{ fontWeight: 500 }}>
        Refills
      </h5>
      <div className='row mt-1'>
        <div className='col-lg-6'>
          <label className='mb-0'>Filter</label>
          <div className=' form-inline'>
            <input
              type='search'
              className='form-control'
              style={{ width: '60%' }}
              placeholder='Search by name and phone, enrolle Id'
              title='Search by name and phone, enrolle Id'
              onChange={searchTable}
              id='advSearchParam'
            />
            {advSearch && (
              <button className='btn btn-success' onClick={searchRemoteData}>
                {isSearch ? <ButtonLoader /> : 'Search'}
              </button>
            )}
          </div>
        </div>

        <div className='col-lg-6 d-flex mt-2 justify-content-end'>
          <div>
            <label className='mb-0'>View</label>
            <select
              className='custom-select'
              // style={{ width: 'auto' }}
              value={refillView}
              onChange={changeRefillView}
            >
              {RefillView.map((view) => (
                <option value={view.value}>{view.text}</option>
              ))}
            </select>
          </div>
          {refillView === 'Custom' && (
            <div className='d-flex'>
              <div>
                <label className='mb-0'>Start Date</label>
                <input
                  type='date'
                  className='form-control'
                  value={dateRange.startDate}
                  onChange={handleDate('startDate')}
                />
              </div>
              <div>
                <label className='mb-0'>End Date</label>
                <input
                  type='date'
                  className='form-control'
                  value={dateRange.endDate}
                  onChange={handleDate('endDate')}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <br />
      <div className='d-flex flex-row flex-wrap justify-content-center align-items-center'>
        <div className='text-success mx-2'>Green - Approved</div>
        <div className='text-info'>Blue - Pending</div>
        <div className='text-danger mx-2'>Red - Declined</div>
      </div>
      <br />
      <div className='container' style={{ backgroundColor: '#F5F5F5' }}>
        {refills ? (
          <div>
            {refills.length === 0 ? (
              <NoTableData />
            ) : (
              refills.map((refill) => (
                <div className='row'>
                  <div className={'col-12 card p-3 card-shadow'}>
                    <div>
                      <small className=''>{formatDate(refill.refills[0].refillDate)}</small>

                      {dataLoading === refill.groupId ? (
                        <span className='float-right'>
                          <small
                            style={{
                              width: '1rem',
                              height: '1rem',
                              marginTop: '-10px',
                            }}
                            className='spinner-border spinner-border'
                            role='status'
                            aria-hidden='true'
                          ></small>
                        </span>
                      ) : (
                        <span className='float-right'>
                          <small
                            className='ml-1 btn btn-sm btn-outline-info text-center bi bi-pencil fs-7'
                            title='Edit basic information'
                            style={{ marginTop: '-10px' }}
                            onClick={() => editBulk(refill.refills[0])}
                          ></small>
                          <span
                            className='ml-1 btn btn-sm btn-outline-success text-center bi bi-check-all fs-7'
                            title='Approve all'
                            style={{ marginTop: '-10px' }}
                            onClick={() => approveRefill(refill.refills[0], true)}
                          ></span>

                          <span
                            className='ml-1 btn btn-sm btn-outline-warning text-center bi bi-x-square fs-7'
                            title='Decline all'
                            style={{ marginTop: '-10px' }}
                            onClick={() =>
                              updateStatus(
                                refill.refills[0].refillId,
                                RefillStatus.Declined,
                                refill.groupId,
                                true,
                                false
                              )
                            }
                          ></span>
                        </span>
                      )}
                    </div>
                    <div className='row mt-2'>
                      <div className='col-md-4 bi bi-person'>
                        <span className='fw-bold'> {refill.refills[0].patientName} </span>
                      </div>
                      <div className='col-md-4 bi bi-telephone'>
                        <span className=''>{refill.refills[0].patientPhone}</span>
                      </div>
                      <div className='col-md-4 bi bi-person-badge'>
                        <span className=' ml-1'>{refill.refills[0].patientEnrollmentCode}</span>
                      </div>
                    </div>
                    <div className='row'>
                      <label
                        className='col-12'
                        style={{ marginBottom: '-10px', marginTop: '15px' }}
                      >
                        Medications
                      </label>
                      {refill.refills.map((data) => (
                        <div key={data.refillId} className='col-md-6'>
                          <div className={showStatusColor(data.refillStatus) + ' card p-3'}>
                            <div>
                              <small className='bi bi-clock' title='Refill date'>
                                <span className='ml-1'>{formatDate(data.refillDate)}</span>
                              </small>

                              {dataLoading === data.refillId ? (
                                <span className='float-right'>
                                  <small
                                    style={{
                                      width: '1rem',
                                      height: '1rem',
                                      marginTop: '-10px',
                                    }}
                                    className='spinner-border spinner-border'
                                    role='status'
                                    aria-hidden='true'
                                  ></small>
                                </span>
                              ) : (
                                <span className='float-right'>
                                  {data.refillStatus !== RefillStatus.Processed &&
                                  data.refillStatus !== RefillStatus.Declined ? (
                                    <small
                                      className='ml-1 btn btn-sm btn-outline-info text-center bi bi-pencil'
                                      title='Edit'
                                      style={{ marginTop: '-10px', fontSize: '0.7rem' }}
                                      onClick={() => editRefill(data)}
                                    ></small>
                                  ) : (
                                    ''
                                  )}
                                  {data.refillStatus === RefillStatus.Pending ||
                                  data.refillStatus === RefillStatus.Declined ? (
                                    <span
                                      className='ml-1 btn btn-sm btn-outline-success text-center bi bi-check-lg'
                                      title='Approve'
                                      style={{ marginTop: '-10px', fontSize: '0.7rem' }}
                                      onClick={() => approveRefill(data, false)}
                                      // onClick={() => updateStatus(data.refillI,d, RefillStatus.Approved)}
                                    ></span>
                                  ) : (
                                    ''
                                  )}

                                  {data.refillStatus === RefillStatus.Pending ||
                                  data.refillStatus === RefillStatus.Approved ? (
                                    <span
                                      className='ml-1 btn btn-sm btn-outline-warning  text-center bi bi-x-lg'
                                      title='Decline'
                                      style={{ marginTop: '-10px', fontSize: '0.7rem' }}
                                      onClick={() =>
                                        updateStatus(
                                          data.refillId,
                                          RefillStatus.Declined,
                                          data.groupId,
                                          false,
                                          false
                                        )
                                      }
                                    ></span>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              )}
                            </div>
                            {/* <div className='row mt-2'>
                              <div className='col-md-7 bi bi-person'>
                                <span className='fw-bold'> {data.patientName} </span>
                              </div>
                              <div className='col-md-5 bi bi-telephone'>
                                <span className=''>{data.patientPhone}</span>
                              </div>
                              <div className='col-md-5 bi bi-person-badge'>
                                <span className=' ml-1'>{data.patientEnrollmentCode}</span>
                              </div>
                            </div> */}
                            <div className='mt-3'>
                              <span className='bi bi-prescription'></span> {data.drugName}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        ) : (
          <TableLoader />
        )}
      </div>
      <br />
      {showPagination ? (
        <PaginationBar
          currentPageNumber={pageInfo.pageIndex}
          numberOfPages={pageInfo.pageCount}
          callback={gotoPage}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default RefillsTable;
