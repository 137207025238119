import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IRefillDTO, IRefillEditDTO } from '../../interfaces/RefillInterface';
import { dosageForm, frequencyList } from '../../utils/drugUtils';
import { endpoints } from '../../utils/URLs';
import { addToast } from '../../utils/toastNotifications';
import LoaderWithText from '../common/LoaderWithText';
import { isFormValidated } from '../../utils/formUtils';
import ButtonLoader from '../common/ButtonLoader';
import { IPricelist } from '../../interfaces/PricelistInterface';
const RefillEditSideModal = ({
  show,
  handleClose,
  refill,
}: {
  show: boolean;
  handleClose: any;
  refill: IRefillDTO | undefined;
}) => {
  const [drugData, setDrugData] = useState<IRefillEditDTO | undefined>();
  const [isLoading, setLoading] = useState(false);
  const [isEdited, setIsEdited] = useState(false);

  const handleDrugChange = (input: string) => (event: any) => {
    setDrugData({
      ...drugData!,
      [input]: event.target.value,
    });
    setIsEdited(true);
  };

  const [drugDuration, setDrugDuration] = useState({
    unit: 1,
    total: 7,
  });

  const updateRefill = async (event: any) => {
    if (!isFormValidated('refill-edits')) {
      return;
    }
    event.preventDefault();

    drugData!.duration = `${drugDuration.unit}/${drugDuration.total}`;
    setLoading(true);
    const url = `${endpoints.refills.mainUrl}/${refill?.refillId}/details`;
    try {
      await axios.put(url, drugData);
      addToast('Refill updated successfully', 'success');
      handleClose();
    } catch (error) {
      addToast('An error occured while updating request', 'error');
    } finally {
      setLoading(false);
    }
  };
  const [drugSearch, setDrugSearch] = useState<IPricelist[]>();

  const loadDrugs = async () => {
    //load from localStorage if available, else load from Api and store in local

    let savedDrugs = localStorage.getItem('drugList');
    if (savedDrugs && savedDrugs !== 'undefined') {
      const parsedDrugs = JSON.parse(savedDrugs);
      setDrugSearch(parsedDrugs);
      return;
    }

    const url = endpoints.pricelist.mainUrl;
    await axios
      .get(url)
      .then((response) => {
        setDrugSearch(response.data.data);
        localStorage.setItem('drugList', JSON.stringify(response.data.data));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDurationChange = (input: string) => (event: any) => {
    setDrugDuration({
      ...drugDuration,
      [input]: event.target.value,
    });
    setIsEdited(true);
  };

  const getDurationParts = (duration: string) => {
    let durationParts = {
      unit: 1,
      total: 7,
    };
    const parts = duration.split('/');
    if (parts.length === 1) {
      return durationParts;
    }
    durationParts = {
      unit: parseInt(parts[0]),
      total: parseInt(parts[1]),
    };
    return durationParts;
  };

  const loadRefill = useCallback(async () => {
    //load exisiting refill drug details
    if (!refill) {
      addToast('Please select a refill', 'error');
      return;
    }
    const url = `${endpoints.refills.mainUrl}/${refill?.refillId}/details`;
    setDrugData(undefined);
    setIsEdited(false);
    try {
      const response = await axios.get(url);
      const data = response.data as IRefillEditDTO;
      setDrugData(response.data);

      const durationParts = getDurationParts(data.duration);
      setDrugDuration(durationParts);
    } catch (error) {
      addToast('Could not load refill info', 'error');
    } finally {
    }
  }, [refill]);

  useEffect(() => {
    // loadDrugs();
    if (show) {
      loadRefill();
    }
  }, [show, loadRefill]);

  useEffect(() => {
    // loadDrugs();
    if (show) {
      loadDrugs();
    }
  }, [show]);

  return (
    <div
      id='sidebarMenu'
      className={
        show
          ? 'col-md-6 col-lg-4 right-sidebar sideshow'
          : 'col-md-6 col-lg-4 right-sidebar sidehide'
      }
    >
      <div className='right-sidebar-sticky pt-3 mt-2 bg-green-light'>
        <div>
          <span
            className='btn btn-sm fs-8'
            style={{ marginLeft: '-10px' }}
            onClick={() => handleClose()}
          >
            Close (x)
          </span>
        </div>
        <label style={{ color: '#1489C8' }}>Edit Refill</label>
        {drugData ? (
          <form id='refill-edits'>
            <fieldset disabled={isLoading}>
              <Modal.Body>
                <h6 style={{ fontWeight: 'bold' }}>Medication</h6>
                <div className='form-group mt-3'>
                  <label>
                    Drug name<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    required
                    value={drugData!.drugName}
                    onChange={handleDrugChange('drugName')}
                    list='druglist'
                  />
                </div>
                <datalist id='druglist'>
                  {drugSearch?.map((drug, key) => (
                    <option key={key}>{drug.drugName}</option>
                  ))}
                </datalist>
                <div className='form-group'>
                  <label>
                    Dosage form<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    required
                    className='form-control'
                    placeholder='e.g Tabs, IV'
                    value={drugData!.drugDose}
                    onChange={handleDrugChange('drugDose')}
                    list='dosage-list'
                  />
                  <datalist id='dosage-list'>
                    {dosageForm.map((dosage, key) => (
                      <option key={key}>{dosage}</option>
                    ))}
                  </datalist>
                </div>
                <div className='form-group'>
                  <label>
                    Strength<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    required
                    className='form-control'
                    placeholder='e.g 500mg, 20ml'
                    value={drugData!.strength}
                    onChange={handleDrugChange('strength')}
                  />
                </div>
                <div className='form-group'>
                  <label>
                    Frequency<span className='text-danger'>*</span>
                  </label>
                  <input
                    type='text'
                    required
                    list='frequencylist'
                    className='form-control'
                    placeholder='e.g tds, bds, daily, nocte'
                    value={drugData!.frequency}
                    onChange={handleDrugChange('frequency')}
                  />
                  <datalist id='frequencylist'>
                    {frequencyList?.map((drugFrequency, key) => (
                      <option key={key}>{drugFrequency}</option>
                    ))}
                  </datalist>
                </div>
                <div className='form-group'>
                  <label>
                    Duration <span className='text-muted'>e.g 2 days, 3 weeks</span>
                    <span className='text-danger'>*</span>
                  </label>
                  <div className='mb-2'>
                    Current duration:
                    <span className='ml-2 text-success'>{drugData.duration}</span>
                  </div>
                  <div className='row'>
                    <div className='col-4'>
                      <input
                        type='number'
                        required
                        max={120}
                        min={1}
                        className='form-control'
                        // placeholder="e.g 2/7, 3/12"
                        value={drugDuration.unit}
                        onChange={handleDurationChange('unit')}
                      />
                    </div>
                    <div className='col-6'>
                      <select
                        required
                        className='custom-select'
                        value={drugDuration.total}
                        onChange={handleDurationChange('total')}
                      >
                        <option value='7'>Days</option>
                        <option value='52'>Weeks</option>
                        <option value='12'>Months</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <label>Refill Date (for this medication only)</label>
                  <input
                    type='date'
                    className='form-control'
                    placeholder=''
                    value={drugData!.refillDate.split('T')[0]}
                    onChange={handleDrugChange('refillDate')}
                  />
                </div>
                <div className='form-group'>
                  <label>Notes</label>
                  <textarea
                    className='form-control'
                    placeholder='e.g Before meals'
                    value={drugData!.notes}
                    onChange={handleDrugChange('notes')}
                  />
                </div>
                {/* <hr />
                <h6 style={{ fontWeight: 'bold' }}>Fulfilment Information</h6>
                <small className='text-danger'>
                  Changes to this section affects all refills in this group
                </small>
                <div className='form-group mt-3'>
                  <label>Enrollee Address</label>
                  <textarea
                    className='form-control'
                    placeholder=''
                    value={drugData!.enrolleeAddress}
                    onChange={handleDrugChange('enrolleeAddress')}
                  />
                </div> */}
              </Modal.Body>

              <Modal.Footer>
                <button
                  className='btn btn-primary'
                  type='submit'
                  onClick={updateRefill}
                  style={{ backgroundColor: '#1489C8' }}
                  disabled={!isEdited}
                >
                  Update Refill
                  {isLoading ? <ButtonLoader /> : ''}
                </button>
              </Modal.Footer>
            </fieldset>
          </form>
        ) : (
          <LoaderWithText />
        )}
      </div>
    </div>
  );
};

export default RefillEditSideModal;
