import { useState, useEffect, useCallback } from 'react';
import HeaderLogo from '../dashboard/HeaderLogo';
import ButtonLoader from '../common/ButtonLoader';
import { useAuth } from 'react-oidc-context';
import { addToast } from '../../utils/toastNotifications';

const LoginForm = (): JSX.Element => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const auth = useAuth();

  const searchParam = new URLSearchParams(window.location.search);
  const accessState = searchParam.get('access');

  const loginAuth = useCallback(async () => {
    setButtonLoading(true);
    await auth
      .signinRedirect()
      .then((response) => {})
      .catch((error) => {
        addToast('Network error occured', 'error');
        console.error(error);
      });
  }, [auth]);

  useEffect(() => {
    //check localstorage if value exist
    const rememberMe = localStorage.getItem('rememberMe');
    const updateAvailable = localStorage.getItem('update-available');
    //if value does not exist, save new value as false
    if (typeof rememberMe === 'undefined' || rememberMe == null) {
      localStorage.setItem('rememberMe', 'false');
      return;
    }

    //check if access is already denied
    if (accessState === 'false') {
      addToast('You do not have access to this application', 'error');
    }

    //if update is available, don't auto sign in
    if (updateAvailable != null && updateAvailable === 'true') {
      //show update available prompt, and return
      let refreshButton = document.getElementById('refresh-btn') as HTMLElement;
      refreshButton.style.display = 'block';
      return;
    }

    //else redirect to login if value is true
    if (rememberMe === 'true') {
      loginAuth();
    }
  }, [loginAuth, accessState]);

  return (
    <div>
      <HeaderLogo />
      <section className='container' style={{ marginTop: '50px' }}>
        <h5 className='text-center'>Welcome to WellaHealth Partner Portal</h5>
        <br />
        <div className='mt-5 row justify-content-center'>
          <div
            className='col-md-4 col-sm-8'
            style={{
              backgroundColor: '#fff',
              padding: '30px 20px',
              boxShadow: '0px 1px 30px 0 rgba(19, 19, 19, 0.2)',
            }}
          >
            <div style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={loginAuth}>
              <div className='card-body text-center' style={{ backgroundColor: '#fff' }}>
                <h5 className='card-title'>
                  <div
                    className='bi bi-person-check-fill'
                    style={{ fontSize: '3rem', color: 'blue' }}
                  ></div>
                </h5>
                <h6 className='card-subtitle mb-2 text-muted'>Login</h6>
                <div>For existing users {buttonLoading ? <ButtonLoader /> : ''}</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginForm;
