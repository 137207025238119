import { useEffect } from 'react';
import { SettingsTabs } from '../../utils/mappings';

const Account = ({ tabKey }: { tabKey: string }) => {
  const accountUrl = process.env.REACT_APP_CHANGE_PASSWORD as string;

  useEffect(() => {
    if (tabKey !== SettingsTabs.Account) {
      return;
    }
  }, [tabKey]);

  return (
    <div>
      <h5 className='mt-5 text-center'>Account</h5>
      <div className='form-group row justify-content-center mt-4'>
        <div className='col-sm-6'>
          <a
            href={accountUrl}
            className='btn btn-success btn-block'
            target='_blank'
            rel='noreferrer'
          >
            Change Password
          </a>
        </div>
      </div>
    </div>
  );
};

export default Account;
