const LoaderWithText = () => {
  return (
    <div className='text-center' style={{ margin: '50px 0px' }}>
      <div
        style={{ width: '3rem', height: '3rem' }}
        className='spinner-border spinner-border-lg'
        role='status'
        aria-hidden='true'
      ></div>
      <div>Loading..</div>
    </div>
  );
};

export default LoaderWithText;
