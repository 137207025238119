export interface IProfileSettings {
  partnerName: string;
  phoneNumber: string;
  contactEmail: string;
  supportEmail: string;
  services: string;
}

export interface IApiCredentialSettings {
  clientId: string;
  clientSecret: string;
  webhookUrl: string;
}

//initializations

export const initialProfile: IProfileSettings = {
  partnerName: '',
  supportEmail: '',
  contactEmail: '',
  services: '',
  phoneNumber: '',
};
