import { Modal } from 'react-bootstrap';
import LoaderWithText from '../common/LoaderWithText';
import { IFulfilmentData } from '../../interfaces/FulfilmentInterfaces';

const DrugsTableModal = ({
  show,
  handleClose,
  fulfilment,
}: // handleDelete,
{
  show: boolean;
  handleClose: any;
  fulfilment: IFulfilmentData | undefined;
  // handleDelete: any;
}) => {
  // const deleteDrug = (drugName: string, dosageForm: string) => {
  //   //should call drug
  //   handleDelete(
  //     fulfilment?.enrollmentCode,
  //     fulfilment?.enrolleePhone,
  //     drugName,
  //     dosageForm
  //   );
  // };

  return (
    <Modal show={show} onHide={handleClose} centered size='lg'>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: '#1489C8' }}>
          Medications for {fulfilment?.enrolleeFirstName} {fulfilment?.enrolleeLastName} (
          {fulfilment?.enrollmentCode})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {fulfilment ? (
          <div className='table-responsive'>
            <table className='table table-hover table-bordered  custom-table'>
              <thead>
                <tr>
                  <th>Drug Name</th>
                  <th>Dosage Form</th>
                  <th>Strength</th>
                  <th>Frequency</th>
                  <th>Duration</th>
                  <th>Notes</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {fulfilment!.drugs.map((drug) => (
                  <tr>
                    <td>{drug.name}</td>
                    <td>{drug.form}</td>
                    <td>{drug.strength}</td>
                    <td>{drug.frequency}</td>
                    <td>{drug.duration}</td>
                    <td>{drug.comments}</td>
                    {/* <td>
                      <button
                        className="bi bi-trash btn-danger btn btn-sm"
                        title="Delete"
                        onClick={() => deleteDrug(drug.name, drug.form)}
                      ></button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <LoaderWithText />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DrugsTableModal;
