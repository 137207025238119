import axios from 'axios';
import { useReducer, useState } from 'react';
import { IFulfilmentData } from '../../interfaces/FulfilmentInterfaces';
import { validFileType } from '../../utils/fileHelper';
import { FulfilmentService } from '../../utils/mappings';
import { addToast } from '../../utils/toastNotifications';
import { endpoints } from '../../utils/URLs';
import ButtonLoader from '../common/ButtonLoader';
import NoTableData from '../common/NoTableData';
import DrugsTableModal from './DrugsTableModal';

const BulkFulfilmentUpload = () => {
  /*
Upload csv
if success, response is display in table
each row shows request detail and click icon to view medications. in modal
can remove request
can remove medication?? probably
can edit medication?? no
click save, each is saved one after the other and status shown
IFulfilmentData
*/
  const [fulfilments, setFulfilments] = useState<IFulfilmentData[] | undefined>();

  const [selectedFulfilment, setSelectedFulfilment] = useState<IFulfilmentData | undefined>();

  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  //https://stackoverflow.com/a/66436476/2929906 : forceUpdate
  //https://stackoverflow.com/a/35004739/2929906 : why it should be sparingly used

  // const [dataLoading, setDataLoading] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);

  const uploadFulfilmentCsv = async (event: any) => {
    const confirm = window.confirm('Do you want to upload this file?');

    if (!confirm) {
      return;
    }
    clearFulfilments();

    let fileUpload: any = document.getElementById('upload-file');
    let fulfilmentService: any = document.getElementById('fulfilment-service') as HTMLSelectElement;

    let uploadedFiles = fileUpload.files;

    if (uploadedFiles.length === 0) {
      addToast('No files selected. Please select a text or csv file', 'warning');
      return;
    }
    if (!validFileType(uploadedFiles[0])) {
      addToast('Wrong file. Upload text or csv file', 'warning');
      return;
    }

    if (uploadedFiles[0].size > 2097152) {
      //if exceeding 2mb
      addToast('Exceed file limit. Max limit is 2MB', 'warning');
      return;
    }

    event.preventDefault();
    setBtnLoading(true);

    const formData = new FormData();
    formData.append('csvFile', uploadedFiles[0]);
    formData.append('fulfilmentService', fulfilmentService.value);

    let url = `${endpoints.fulfilments.mainUrl}/upload`;

    try {
      // setIsUploading(true);
      const response = await axios.post(url, formData);
      const data = response.data as IFulfilmentData[];

      //for each data, set the loading state and an id for internal use
      let i = 0;
      const mapData = data.map((fulfilment) => {
        fulfilment.loadingStatus = 'Pending';
        fulfilment.loadingId = i++;
        return fulfilment;
      });
      setFulfilments(mapData);
    } catch (error) {
      addToast('There was an error uploading the csv. Please check the uploaded file', 'error');
      console.error(error);
    } finally {
      setBtnLoading(false);
    }
  };

  const uploadAllFulfilments = async () => {
    if (!fulfilments) {
      addToast('Please upload a csv', 'error');
      return;
    }

    if (fulfilments.length === 0) {
      return;
    }
    for (let i = 0; i < fulfilments!.length; i++) {
      fulfilments[i].loadingStatus = 'Processing';
      setFulfilments(fulfilments);
      forceUpdate();
      const result = await uploadFulfilment(fulfilments![i]);

      fulfilments[i].loadingStatus = result;

      setFulfilments(fulfilments);
      forceUpdate();
    }

    const failures = fulfilments.filter((x) => x.loadingStatus === 'Failed');
    if (failures.length > 0) {
      addToast(
        `${failures.length} fulfilments failed to process. Please click 'Process' to try again`,
        'error',
        false
      );

      setFulfilments(failures);
    } else {
      addToast('All fulfilments processed', 'success', false);
    }
  };

  const uploadFulfilment = async (fulfilment: IFulfilmentData) => {
    //setDataLoading(`${fulfilment.enrollmentCode}${fulfilment.enrolleePhone}`);
    let result = 'Failed';

    const url = `${endpoints.fulfilments.mainUrl}`;

    try {
      await axios.post(url, fulfilment);
      result = 'Success';
    } catch (error) {
      console.error(error);
    } finally {
      //setDataLoading("");
    }
    return result;
  };

  const clearFulfilments = () => {
    if (fulfilments) {
      fulfilments!.length = 0;
      //fulfilments
      setFulfilments([]);
    }
  };

  const showDrugs = (fulfilment: IFulfilmentData) => {
    setSelectedFulfilment(fulfilment);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  // const deleteDrug = (
  //   enrolleeCode: string,
  //   enrolleePhone: string,
  //   drugName: string,
  //   dosageForm: string
  // ) => {
  //   //search for the fulfilment
  //   const fulfilmentMatch = fulfilments?.filter(
  //     (x) =>
  //       x.enrollmentCode === enrolleeCode && x.enrolleePhone === enrolleePhone
  //   );
  //   if (fulfilmentMatch?.length === 0) {
  //     return;
  //   }
  //   const selectedFulfilment = fulfilmentMatch![0];
  //   const updatedFulfilment = fulfilmentMatch![0];
  //   //exclude deleted drug
  //   const excludedDrugs = updatedFulfilment.drugs.filter(
  //     (x) => x.name !== drugName && x.form !== dosageForm
  //   );

  //   updatedFulfilment.drugs = excludedDrugs;

  //   fulfilments![fulfilments!.indexOf(selectedFulfilment)] = updatedFulfilment;

  //   setFulfilments(fulfilments);
  //   //update the array
  // };

  const drugTableModal = (
    <DrugsTableModal
      show={showModal}
      handleClose={closeModal}
      fulfilment={selectedFulfilment}
      // handleDelete={null}
    />
  );

  return (
    <div>
      {drugTableModal}
      <h5 className='text-center' style={{ marginTop: '20px', fontWeight: 500 }}>
        Bulk Fulfilments
      </h5>
      <br />
      <form className='row'>
        <div className='col-sm-5'>
          <input type='file' className='form-control' id='upload-file' required />
        </div>
        <div className='col-sm-5'>
          <select className='custom-select' id='fulfilment-service'>
            {FulfilmentService.map((service, key) => (
              <option key={key}>{service.value}</option>
            ))}
          </select>
        </div>
        <div className='col-sm-2'>
          <button className='btn btn-primary' onClick={uploadFulfilmentCsv}>
            Upload
            {btnLoading ? <ButtonLoader /> : ''}
          </button>
        </div>
      </form>
      <br />

      {fulfilments && fulfilments?.length > 0 ? (
        <div className=' mt-2' style={{ backgroundColor: 'white' }}>
          <br />
          <div>
            <button className='btn btn-primary mr-3' onClick={() => uploadAllFulfilments()}>
              Process - {fulfilments?.length}
            </button>
            <button className='btn btn-danger' onClick={clearFulfilments}>
              Clear
            </button>
          </div>
          <br />

          <div className='table-responsive'>
            <table id='refill-table' className='table table-hover table-bordered'>
              <thead>
                <tr>
                  <th>Status</th>
                  <th>Enrollment Code</th>
                  <th>Full name</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th>Drugs</th>
                </tr>
              </thead>
              <tbody>
                {fulfilments?.length === 0 ? (
                  <NoTableData />
                ) : (
                  fulfilments?.map((data) => (
                    <tr key={data.enrollmentCode}>
                      <td className='text-center'>
                        {data.loadingStatus === 'Pending' && (
                          <span
                            className='bi bi-hourglass'
                            title='Pending'
                            style={{ fontSize: '1.2rem' }}
                          />
                        )}
                        {data.loadingStatus === 'Processing' && (
                          <span
                            style={{ width: '1.5rem', height: '1.5rem' }}
                            className='spinner-border spinner-border-lg'
                            role='status'
                            aria-hidden='true'
                            title='Processing'
                          />
                        )}
                        {data.loadingStatus === 'Success' && (
                          <span
                            className='bi bi-check-lg text-success'
                            title='Success'
                            style={{ fontSize: '1.2rem' }}
                          />
                        )}
                        {data.loadingStatus === 'Failed' && (
                          <span
                            className='bi bi-x-lg text-danger'
                            title='Failed'
                            style={{ fontSize: '1.2rem' }}
                          />
                        )}

                        {/* if pending show hour glass, processing, sent */}
                      </td>
                      <td>{data.enrollmentCode}</td>
                      <td>
                        <span className='mr-2'>{data.enrolleeFirstName}</span>
                        {data.enrolleeLastName}
                      </td>
                      <td>{data.enrolleePhone}</td>
                      <td>{data.enrolleeAddress}</td>
                      <td>
                        <button className='btn btn-primary' onClick={() => showDrugs(data)}>
                          View Drugs ({data.drugs.length})
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
export default BulkFulfilmentUpload;
