import axios from 'axios';
import { useContext, useEffect, useCallback } from 'react';
import { AppContext } from '../../context/AppContext';
import { AppState } from '../../interfaces/AppInterfaces';
import { endpoints } from '../../utils/URLs';
const Header = () => {
  const { appState, dispatch }: { appState: AppState; dispatch: any } = useContext(AppContext);

  //if profile not in state, load profile from api
  const loadProfile = useCallback(async () => {
    const url = endpoints.profile.mainUrl;

    try {
      const response = await axios.get(url);
      const profile: AppState = {
        partnerName: response.data.partnerName,
        logoUrl: '',
      };
      //dispatch to state
      dispatch({
        type: 'SET_PROFILE',
        payload: profile,
      });
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  useEffect(() => {
    if (appState.partnerName === '') {
      loadProfile();
    }
  }, [loadProfile, appState]);

  return (
    <nav
      className='navbar navbar-light sticky-top flex-md-nowrap p-0 shadow'
      style={{ height: 'auto', backgroundColor: '#ffffff' }}
    >
      <a className='col-md-3 col-lg-2 mr-0 px-3' style={{ margin: '10px 0px' }} href='/'>
        <img
          className='logo-img'
          src={require('../../assets/images/wellahealth.svg').default}
          alt='wellahealth'
        />
      </a>
      <button
        className='navbar-toggler position-absolute d-md-none collapsed'
        type='button'
        data-toggle='collapse'
        data-target='#sidebarMenu'
        aria-controls='sidebarMenu'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span
          className='navbar-toggler-icon'
          data-toggle='collapse'
          data-target='#sidebarMenu'
        ></span>
      </button>

      <ul className='navbar-nav' style={{ marginRight: '50px' }}>
        <li className='nav-item dropdown text-nowrap text-right'>
          {/* <span>
						{notificationIcon !== '' ? (
							<button id='installBtn' className='btn'>
								{notificationIcon}
							</button>
						) : (
							''
						)}
					</span> */}
          <div className='align-items-middle'>
            {appState.partnerName}
            <span id='headway-icon' className='float-left mr-2'></span>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Header;
