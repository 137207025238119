import { useEffect, useState } from 'react';
import { IPharmacyList } from '../../interfaces/PharmacyInterface';

const PharmacySideModal = ({
  showModal,
  modalData,
  close,
  setModalPharmacy,
  nextLevel,
  filter,
  showSortingCenter,
}: {
  showModal: boolean;
  modalData: IPharmacyList[] | undefined;
  close: Function;
  setModalPharmacy?: Function;
  nextLevel?: string;
  filter?: Function;
  showSortingCenter: boolean;
}) => {
  const [sortingCenter, setSortingCenter] = useState<IPharmacyList | undefined>(undefined);

  const setSelectedPharmacy = (pharmacy: IPharmacyList, isSortingCenter: boolean) => {
    if (setModalPharmacy) {
      setModalPharmacy(pharmacy, isSortingCenter);
    }
  };

  const loadMore = () => {
    if (filter) {
      filter(nextLevel);
    }
  };

  useEffect(() => {
    const savedPharmacyList = sessionStorage.getItem('pharmacy-list');
    if (!savedPharmacyList || savedPharmacyList === null) {
      return;
    }

    const parsedList = JSON.parse(savedPharmacyList) as IPharmacyList[];

    const sortingCenter = parsedList.filter((x) => x.pharmacyCode === 'WHP12170')[0];

    setSortingCenter(sortingCenter);
  }, [showSortingCenter]);

  return (
    <div
      id='sidebarMenu-1'
      className={
        showModal
          ? 'col-md-6 col-lg-4 right-sidebar sideshow'
          : 'col-md-6 col-lg-4 right-sidebar sidehide'
      }
    >
      <div className='right-sidebar-sticky pt-3 mt-2'>
        <div>
          <span
            className='btn btn-sm fs-7 text-danger float-right'
            style={{ marginLeft: '-10px' }}
            onClick={() => close()}
          >
            Close (x)
          </span>
        </div>
        {modalData ? (
          <div>
            Select a pharmacy
            {showSortingCenter && sortingCenter ? (
              <div
                key={1}
                className='mt-4 card p-3 card-shadow-selected'
                onClick={() => setSelectedPharmacy(sortingCenter, true)}
              >
                <small className='text-success'>Recommended for Chronic Medications Only</small>
                <div className='fw-bold mt-2'>{sortingCenter.pharmacyName}</div>
                <div className='mt-2'>{sortingCenter.address}</div>
                <small className='mt-2'>Cheaper medications with FREE next day delivery</small>
              </div>
            ) : (
              ''
            )}
            {modalData.map((pharmacy, key) => (
              <div
                key={key}
                className='mt-4 card p-3 card-shadow'
                onClick={() => setSelectedPharmacy(pharmacy, false)}
              >
                <div className='fw-bold'>{pharmacy.pharmacyName}</div>
                <div className='mt-2'>{pharmacy.address}</div>
              </div>
            ))}
            {nextLevel !== '' ? (
              <div className='mt-4 card p-3 card-shadow' onClick={loadMore}>
                <div className='fw-bold'>Load more from {nextLevel}</div>
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default PharmacySideModal;
