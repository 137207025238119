import { useEffect, useState } from 'react';
import { endpoints } from '../../utils/URLs';
import axios from 'axios';
import { IClaimDTO } from '../../interfaces/ClaimInterface';
import NoTableData from '../common/NoTableData';
import TableLoader from '../common/TableLoader';

const Claims = () => {
  const [claims, setClaims] = useState<IClaimDTO[] | undefined>();

  const loadClaims = async () => {
    const url = endpoints.claims.mainUrl;

    try {
      const response = await axios.get(url);
      const data = response.data;
      setClaims(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadClaims();
  }, []);

  return (
    <div>
      <div className='table-responsive'>
        <h5 className='text-center' style={{ marginTop: '20px', fontWeight: 500 }}>
          Claims
        </h5>

        <br />
        <div className='table-responsive'>
          <table className='table table-hover table-borderless custom-table'>
            <thead>
              <tr>
                <th>Claim Date</th>
                <th>Subscription Code</th>
                <th>Beneficiary Name</th>
                <th>Claim Amount</th>
                <th>Claim Status</th>
              </tr>
            </thead>
            {claims ? (
              <tbody>
                {claims.length === 0 ? (
                  <NoTableData />
                ) : (
                  claims.map((data) => (
                    <tr key={data.insuranceClaimId} className='custom-tr'>
                      <td>{new Date(data.claimDate).toLocaleString()}</td>

                      <td>{data.subscriptionCode}</td>
                      <td>{data.beneficiaryName}</td>
                      <td>{data.claimAmount}</td>
                      <td>{data.claimStatus}</td>
                    </tr>
                  ))
                )}
              </tbody>
            ) : (
              <TableLoader />
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Claims;
