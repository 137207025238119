const toCSV = (table: HTMLElement) => {
  // query rows
  const rows = table.querySelectorAll('tr');

  return [].slice
    .call(rows)
    .map((row: any) => {
      // query all cells
      const cells = row.querySelectorAll('th,td');
      return [].slice
        .call(cells)
        .map((cell: any) => {
          return cell.textContent;
        })
        .join(',');
    })
    .join('\n');
};

const download = (text: any, filename: string) => {
  const link = document.createElement('a');
  link.setAttribute('href', `data:text/csv;charset=utf-8,${encodeURIComponent(text)}`);
  link.setAttribute('download', filename);

  link.style.display = 'none';
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};

export const exportToCSV = (tableId: string, fileName: string) => {
  const table = document.getElementById(tableId);

  // export to csv
  const csv = toCSV(table as HTMLElement);
  // download file
  const fileDownload = `${fileName.replace('csv', '')}.csv`;
  download(csv, fileDownload);
};
