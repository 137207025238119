import * as Sentry from '@sentry/react';
import axios, { InternalAxiosRequestConfig, AxiosError } from 'axios';
import { addToast } from './toastNotifications';

export const toggleSpinner = (state: string) => {
  const spinner = document.getElementById('request-spinner');

  if (spinner) {
    if (state.includes('show')) {
      spinner.classList.remove('d-none');
      spinner.classList.add('d-block');
    } else {
      spinner.classList.remove('d-block');
      spinner.classList.add('d-none');
    }
  }
};

axios.interceptors.request.use(
  function (config: InternalAxiosRequestConfig) {
    if (config.method === 'get') {
      toggleSpinner('show');
    }
    return config;
  },
  function (error) {
    toggleSpinner('hide');
    return Promise.reject(error);
  }
);

const setAuthToken = (token?: string): void => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
};

interface ErrorResponse {
  detail?: string;
  errors?: Record<string, string[]>;
}

export const handleAxiosError = (error: unknown) => {
  toggleSpinner('hide');

  if (!axios.isAxiosError(error)) {
    addToast('Network error. Please check your connection.', 'error');
    return Promise.reject(error);
  }

  const axiosError = error as AxiosError<ErrorResponse>;

  if (!axiosError.response) {
    addToast('No server response. Please try again later.', 'error');
    return Promise.reject(error);
  }

  const status = axiosError.response.status;
  const data = axiosError.response.data;

  switch (status) {
    case 400:
      handleBadRequest(data);
      break;
    case 401:
      addToast('Your session has expired. Please login again', 'error');
      window.location.href = '/';
      break;
    case 403:
      addToast('You do not have permission to perform this action', 'error');
      break;
    case 404:
      addToast(`Not found. ${data.detail || 'Invalid URI'}`, 'error');
      break;
    case 409:
      addToast(`${data.detail || 'Conflict occurred'}`, 'error');
      break;
    case 422:
      addToast(`Unprocessable. ${data.detail}`, 'error');
      break;
    default:
      Sentry.captureException(error);
      addToast('There is a server error. Please contact admin. Code 500.', 'error');
      break;
  }

  return Promise.reject(error);
};

const handleBadRequest = (data: ErrorResponse) => {
  if (data.errors) {
    const responseErrors = data.errors;
    const allErrors = Object.values(responseErrors)[0];

    if (Array.isArray(allErrors)) {
      addToast(`Bad request. ${allErrors[0]}`, 'error');
    } else {
      addToast(`Bad request. ${JSON.stringify(responseErrors)}`, 'error');
    }
  } else if (data.detail) {
    addToast(`Bad request. ${data.detail}`, 'error');
  } else {
    addToast('Bad request. Please check your input.', 'error');
  }
  Sentry.captureException(new Error('Bad Request'));
};

axios.interceptors.response.use((response) => {
  toggleSpinner('hide');
  return response;
}, handleAxiosError);

export default setAuthToken;
