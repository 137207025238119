import { IDataCardProps } from '../../../interfaces/UtilizationInterfaces';
import { NairaSymbol } from '../../../utils/mappings';

const DataCard = ({ title, value, iconClass, iconBgClass, isCurrency }: IDataCardProps) => {
  return (
    <div className='col-lg-3 col-md-4 col-sm-6'>
      <div className='bg-white shadow rounded card py-3 px-4'>
        <div className='d-flex justify-content-between align-items-center'>
          <h3 className='text-muted fs-7 mb-0'>
            <strong>{title}</strong>
          </h3>
          <div className={`icon-box text-white rounded-circle ${iconBgClass}`}>
            <i className={iconClass}></i>
          </div>
        </div>
        <h4 className='fw-normal text-center mt-3'>
          {isCurrency && NairaSymbol}
          {value.toLocaleString()}
        </h4>
      </div>
    </div>
  );
};

export default DataCard;
