import { StatusTrackingDTO } from '../../interfaces/StatusTrackingInterface';
import { formatLongDateTime } from '../../utils/dateTimeUtils';

const TrackingBoard = ({
  trackingInfo,
  statusRank,
}: {
  trackingInfo: StatusTrackingDTO;
  statusRank: number;
}) => {
  const getIconAndColor = (status: string, rank: number) => {
    const iconColor = rank > 1 ? 'text-success' : 'text-info';

    switch (status) {
      case 'Completed':
        return `bi bi-check-circle-fill text-success`;
      case 'Approved':
        return `bi bi-truck text-success`;
      //return 'bi bi-check2-circle text-success';
      case 'Dispensed':
        return `bi bi-box2-fill ${iconColor}`;
      case 'Processing':
        return `bi bi-hourglass-split ${iconColor}`;
      case 'Hold':
        return `bi bi-pause-circle-fill ${iconColor}`;
      case 'Cancelled':
        return `bi bi-stop-circle-fill text-danger`;
      default:
        return `bi bi-stopwatch-fill text-success ${iconColor}`;
    }
  };

  const isFinalStatus = (status: string) => {
    const isFinal = status === 'Completed' || status === 'Cancelled';

    return isFinal;
  };

  const getCorrelatedStatus = (status: string) => {
    switch (status) {
      case 'Completed':
        return 'Medication picked up / delivered';
      case 'Approved':
        return 'Medications ready for Pickup / out for Delivery';
      case 'Dispensed':
        return 'Packaging your medications';
      case 'Processing':
        return 'Processing your Fulfilment request';
      case 'Hold':
        return 'Fulfilment on Hold';
      case 'Cancelled':
        return 'Fulfilment Cancelled';
      default:
        return 'Fulfilment received';
    }
  };

  return (
    <div>
      <div className='row'>
        <div className='col-2 text-right'>
          <span
            className={`${getIconAndColor(trackingInfo.fulfilmentStatus, statusRank)} fs-5`}
          ></span>
        </div>
        <div className='col-10 mt-auto'>
          <h6 className='fw-bold'>
            {getCorrelatedStatus(trackingInfo.fulfilmentStatus)}
            {isFinalStatus(trackingInfo.fulfilmentStatus) ? (
              <div className='fw-light fs-9 mt-1'>
                {formatLongDateTime(
                  trackingInfo.statusNoteEntries.length > 0
                    ? trackingInfo.statusNoteEntries[0].dateCreated
                    : ''
                )}
              </div>
            ) : (
              ''
            )}
          </h6>
        </div>
      </div>
      {!isFinalStatus(trackingInfo.fulfilmentStatus) ? (
        <div className='row'>
          <div className='col-2'>
            <div className={statusRank > 1 ? 'track-line bg-success' : 'track-line bg-info'}></div>
          </div>
          <div className='col-10 fw-light fs-7 mt-1'>
            <div></div>
            {trackingInfo.statusNoteEntries.length > 0
              ? trackingInfo.statusNoteEntries.map((status) => (
                  <div className='mt-2'>
                    <span className='bi bi-bar-chart-steps mr-2'></span>
                    {status.statusNote}
                    <div className='fs-9'>{formatLongDateTime(status.dateCreated)}</div>
                  </div>
                ))
              : ''}
            <div className='invisible'>.</div>
          </div>
        </div>
      ) : (
        <div></div>
        // <div className='row'>
        //   <div className='col-2'>
        //     <div className=''></div>
        //   </div>
        //   <div className='col-10 fw-light fs-7 mt-1'>
        //     <div></div>
        //     {trackingInfo.statusNoteEntries.length > 0
        //       ? trackingInfo.statusNoteEntries.map((status) => (
        //           <div className='mt-2'>
        //             <span className='bi bi-bar-chart-steps mr-2'></span>
        //             {status.statusNotes}
        //             <div className='fs-9'>{formatLongDateTime(status.dateCreated)}</div>
        //           </div>
        //         ))
        //       : ''}
        //     <div className='invisible'>.</div>
        //   </div>
        // </div>
      )}
    </div>
  );
};

export default TrackingBoard;
