export interface IFulfilmentResponse {
  requestId: number;
  enrollmentId: number;
  enrollmentCode: string;
  enrolleeFirstName: string;
  enrolleeLastName: string;
  enrolleePhone: string;
  enrolleeEmail: string;
  enrolleeAddress: string;
  fulfilmentStatus: string;
  fulfilmentService: number;
  pharmacyName: string;
  pharmacyCode: string;
  drugs: string[];
  tests: number[];
  pickUpDate: number;
  notes: string;
  dateCreated: string;
}

export interface IFulfilmentData {
  enrollmentCode: string;
  enrolleeFirstName: string;
  enrolleeLastName: string;
  enrolleePhone: string;
  enrolleeEmail: string;
  enrolleeGender: string;
  enrolleeAddress: string;
  pharmacyCode: string;
  pharmacyAddress: string;
  fulfilmentStatus: string;
  fulfilmentService: string;
  diagnosis: string;
  notes: string;
  preAuthorizationCode: string;
  isDelivery: boolean;
  drugs: Array<IDrugs>;
  tests: Array<ITests>;

  //local state. use for array manip only
  loadingStatus?: string; //for local use
  loadingId?: number;
  showSortingCenter: boolean;
}

export interface IDrugs {
  id: number; //local id
  drugId: number;
  name: string;
  form: string;
  frequency: string;
  strength: string;
  duration: string;
  comments: string;
  drugPrice: number;
  unitPrice: number;
}

export interface ITests {
  id: number; //local id
  testId: number;
  testName: string;
  cost: number;
  fileName: string;
  notes: string;
}

export interface ILoginDTO {
  username: string;
  email?: string;
  password: string;
}

// export interface IDrugSearchData {
//   priceListId: number;
//   drugName: string;
//   drugPrice: number;
//   unitPrice: number;
//   notes: string;
//   dateCreated: string;
//   dateUpdated: string;
// }

export interface IFulfilmentSummary {
  totalFulfilments: number;
  totalPending: number;
  totalToday: number;
}

export interface IFulfilmentPatientData {
  patientId: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  hmoId: string;
  email: string;
  gender: string;
  address: string;
  locationLongitude: string;
  locationLatitude: string;
  pharmacyCode: string;
  pharmacyName: string;
  fulfilmentService: string;
}

export const initialFulfilmentData: IFulfilmentData = {
  enrollmentCode: '',
  enrolleeFirstName: '',
  enrolleeLastName: '',
  enrolleeAddress: '',
  enrolleeEmail: '',
  enrolleePhone: '',
  enrolleeGender: 'Female',
  drugs: [],
  tests: [],
  fulfilmentService: 'Chronic',
  fulfilmentStatus: 'Pending',
  pharmacyCode: 'WHP10390',
  pharmacyAddress: '',
  notes: '',
  diagnosis: '',
  preAuthorizationCode: '',
  isDelivery: false,
  showSortingCenter: false,
};
